import api from '../../api'
import {
	convertKeysToCamelCase,
	createGetters,
	createPaginaion,
} from 'vuelpers'

const state = () => ({
	drivers: createPaginaion(),
	companylist: [],
	companyteams: [],
	status: '',
})

const getters = {
	...createGetters({
		drivers: (state, _getter, _rootState, _rootGetters) => ({
			...state.drivers,
			data: state.drivers.data.map((driver) => ({
				...driver,
				isOnline: _rootGetters['pusher/$onlineDrivers'].some(
					(user) => user.id === driver.user.id
				),
			})),
		}),
	}),
	companylist: (state) => state.companylist,
	companyteams: (state) => state.companyteams,
}
const mutations = {
	CREATE_DRIVERS(state, driver) {
		state.drivers.total += 1
		state.drivers.data.unshift(driver)
	},
	DRIVERS_DATA(state, drivers) {
		state.drivers = drivers
	},
	COMAPNY_LIST(state, companylist) {
		state.status = 'success'
		state.companylist = companylist
	},
	COMPANY_TEAM_LIST(state, teams) {
		state.status = 'success'
		state.companyteams = teams
	},
	STATUS_UPDATE(state, driver) {
		state.status = 'success'
	},
	DRIVERS_UPDATE(state, payload) {
		const index = state.drivers.data.findIndex(
			(driver) => driver.id === payload.id
		)
		if (index !== -1) {
			state.drivers.data.splice(index, 1, payload)
		}
	},
	DRIVERS_DELETE(state, id) {
		state.status = 'success'
		state.drivers.data = state.drivers.data.filter((driver) => {
			return driver.id !== id
		})
	},
}
const actions = {
	CreateDrivers({ commit }, driver) {
		return new Promise((resolve, reject) => {
			api.post('/api/drivers/create', driver)
				.then((res) => {
					if (!res.data.error) {
						commit('CREATE_DRIVERS', res.data.driver)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	DriversUpdate({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.post(`/api/drivers/update`, payload)
				.then((res) => {
					commit('DRIVERS_UPDATE', res.data.driver)

					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	fetchOrders(_, params = {}) {
		return new Promise((resolve) => {
			api.get(`/api/orders/all-orders`, { params })
				.then((res) => resolve([false, res]))
				.catch((err) => resolve([true, err.response || err]))
		})
	},
	fetchDrivers(_, params = {}) {
		return new Promise((resolve) => {
			api.get(`/api/drivers/index`, { params })
				.then((res) => resolve([false, res]))
				.catch((err) => resolve([true, err.response || err]))
		})
	},
	DriversData({ commit }, payload = {}) {
		return new Promise((resolve, reject) => {
			payload = typeof payload === 'number' ? { page: payload } : payload
			api.get(`/api/drivers/index`, { params: payload })
				.then((res) => {
					commit('DRIVERS_DATA', convertKeysToCamelCase(res.data.drivers))
					return resolve(res)
				})
				.catch((err) => reject(err))
		})
	},

	Driversdelete({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/drivers/delete/${id}`)
				.then((res) => {
					commit('DRIVERS_DELETE', id), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	CompanyAllList({ commit }) {
		return new Promise((resolve, reject) => {
			api.get('/api/company-list')
				.then((res) => {
					const companylist = res.data.companies
					commit('COMAPNY_LIST', companylist), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateDriverStatus({ commit }, data) {
		return new Promise((resolve, reject) => {
			api.post('/api/drivers/status-update', data)
				.then((res) => {
					const driver = res.data.driver
					commit('DRIVERS_UPDATE', driver),
						commit('STATUS_UPDATE', driver),
						resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	companyWiseTeams({ commit }) {
		return new Promise((resolve, reject) => {
			api.get('/api/teams/company-wise-teams')
				.then((res) => {
					const teams = res.data.teams
					commit('COMPANY_TEAM_LIST', teams), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
