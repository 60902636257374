import axios from 'axios'
import { SERVER_BASE_URL } from '@/consts'
import store from '../store'

// import camelcaseKeys from 'camelcase-keys'

export const handleRequest = (request) => {
	return new Promise((resolve) => {
		request
			.then((res) => resolve([null, res.data]))
			.catch((err) => resolve([err, null]))
	})
}

const axiosInstance = axios.create({
	baseURL: SERVER_BASE_URL,
})

axiosInstance.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (('axiosInstance', error.response?.status == 401)) {
			if (!error.request.responseURL.includes('logout')) {
				store.dispatch('logout')
			}
		}
		if (('axiosInstance', error.response?.status == 403)) {
			alert(error.response.data.message)
		}
		return error
	}
)

// axiosInstance.interceptors.response.use((response) => {
// 	response.data = camelcaseKeys(response.data, { deep: true })
// 	return response
// })

export default axiosInstance
