import api from '../../api'
import { createPagination } from 'vuelpers'

let state = {
	pickup_points: createPagination(),
}

let getters = {
	PickupPoints: (state) => state.pickup_points,
}
let mutations = {
	CREATE_PICKUP_POINT(state, pickup_point) {
		if (typeof pickup_point.location === 'string') {
			try {
				pickup_point.location = JSON.parse(pickup_point.location)
			} catch (_error) {
				//
			}
		}
		state.pickup_points.data.unshift(pickup_point)
	},
	PICKUP_POINT_DATA(state, payload) {
		state.pickup_points = {
			...payload,
			data: payload.data.map((item) => {
				if (typeof item.location === 'string') {
					try {
						item.location = JSON.parse(item.location)
					} catch (_error) {
						//
					}
				}
				return item
			}),
		}
	},
	PICKUP_POINT_UPDATE(state, pickup_point) {
		if (typeof pickup_point.location === 'string') {
			try {
				pickup_point.location = JSON.parse(pickup_point.location)
			} catch (_error) {
				//
			}
		}
		state.pickup_points.data = state.pickup_points.data.map((item) => {
			return item.id === pickup_point.id ? pickup_point : item
		})
	},
	PICKUP_POINT_DELETE(state, id) {
		state.pickup_points.data = state.pickup_points.data.filter(
			(pickup_point) => pickup_point.id !== id
		)
	},
}
let actions = {
	GetAllPickupPoint(_) {
		return new Promise((resolve, reject) => {
			api.get('/api/pickup-point/all')
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	PickupPointIndex({ commit }, payload = {}) {
		if (!payload.store_id) {
			commit('PICKUP_POINT_DATA', { data: [] })
			return
		}
		return new Promise((resolve, reject) => {
			// payload = typeof payload === 'number' ? { page: payload } : { page: 1 }
			api.get('/api/pickup-point/index', { params: payload })
				.then((res) => {
					commit('PICKUP_POINT_DATA', res.data.pickup_points)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	PickupPointCreate({ commit }, pickup_point) {
		return new Promise((resolve, reject) => {
			api.post('/api/pickup-point/store', pickup_point)
				.then((res) => {
					commit('CREATE_PICKUP_POINT', res.data.pickup_points)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	PickupPointUpdate({ commit }, pickup_point) {
		return new Promise((resolve, reject) => {
			api.post(`/api/pickup-point/update`, pickup_point)
				.then((res) => {
					const pickup_points = res.data
					commit('PICKUP_POINT_UPDATE', pickup_points)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	PickupPointDelete({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/pickup-point/delete/${id}`)
				.then((res) => {
					// commit('PICKUP_POINT_DELETE', id)

					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
