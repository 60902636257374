import { enable, disable, isEnabled, setFetchMethod } from 'darkreader'

const DarkReader = {
	install(Vue, options) {
		setFetchMethod(window.fetch)

		const onChange = () => {
			const enabled = isEnabled()

			$darkreader.isEnabled = enabled
			localStorage.setItem('darkreader', enabled)
		}

		const onDisable = () => {
			disable()
			onChange()
		}

		const onEnable = () => {
			enable({
				sepia: 0,
				contrast: 100,
				brightness: 100,
			})
			onChange()
		}

		const onToggle = () => {
			if (isEnabled()) {
				return onDisable()
			}
			onEnable()
		}

		const $darkreader = Vue.observable({
			isEnabled: isEnabled(),
			toggle: onToggle,
			enable: onEnable,
			disable: onDisable,
		})

		if (['true', true, '1', 1].includes(localStorage.getItem('darkreader'))) {
			onEnable()
		}

		Vue.prototype.$darkreader = $darkreader
	},
}

export default DarkReader
