import api from '../../api'
import {
	convertKeysToCamelCase,
	createGetters,
	createPaginaion,
} from 'vuelpers'

const state = () => ({
	categories: createPaginaion(),
})

const getters = {
	...createGetters({
		categories: (state, _getter, _rootState, _rootGetters) => ({
			...state.categories,
			data: state.categories.data.map((category) => ({
				...category,
			})),
		}),
	}),
}
const mutations = {
	FETCH_CATEGORIES(state, categories) {
		state.categories = categories
	},
}
const actions = {
	fetchCategories({ commit }, params = {}) {
		return new Promise((resolve) => {
			api.get(`/api/category`, { params })
				.then((res) => {
					commit('FETCH_CATEGORIES', res.data.categories)
					resolve([false, res])
				})
				.catch((err) => resolve([true, err.response || err]))
		})
	},
	createCategory(_, product) {
		return new Promise((resolve, reject) => {
			api.post('/api/category', product)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateCategory(_, payload) {
		return new Promise((resolve, reject) => {
			api.patch(`/api/category/update`, payload)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	deleteCategory(_, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/category/${id}`)
				.then((res) => {
					resolve([false, res])
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
