export const env = (key = '', fallback = '', prefix = 'VUE_APP_') => {
	if (!key.startsWith(prefix)) key = prefix + key
	return process.env[key] || fallback
}

export const page = (name) => () => `@/pages/${name}.vue`
export const layout = (name) => () => `@/layouts/${name}.vue`

export const createPagination = (v = {}) => {
	return {
		data: v?.data || [],
		total: v?.total ?? 0,
		page: v?.page ?? 1,
		perPage: v?.perPage ?? 500,
		currentPage: v?.currentPage ?? 1,
		isLoading: v?.isLoading ?? true,
		isLoaded: v?.isLoaded ?? false,
		isRefetching: v?.isRefetching ?? false,
	}
}

export const qs = {
	stringify(data) {
		var str = []
		// eslint-disable-next-line no-prototype-builtins
		for (var p in data)
			if (data.hasOwnProperty(p)) {
				str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]))
			}
		return str.join('&')
	},
}

export const toSnakeCase = (data) => {
	const snake = (str) => str.replace(/[A-Z]/g, (l) => `_${l.toLowerCase()}`)
	if (typeof data === 'string') return snake(data)
	return Object.entries(data).reduce((acc, [key, value]) => {
		return {
			...acc,
			[snake(key)]: value,
		}
	})
}

export const toFormData = (obj) => {
	let formData = new FormData()
	Object.entries(obj).forEach(([key, value]) => {
		formData.append(key, value)
	})
	return formData
}
