import api from '../../api'

import { handleRequest } from '../../api'
import { createMutations, handleAction } from 'vuelpers'

const initialState = () => ({
	driversInvoice: {
		driver: null,
	},
	driversInvoices: [],
	adminInvoices: [],
	storeInvoices: [],
	companyInvoices: [],
	stores: {
		total: 0,
		per_page: 15,
		current_page: 1,
		data: [],
	},
	drivers: {
		total: 0,
		per_page: 15,
		current_page: 1,
		data: [],
	},
	companies: {
		total: 0,
		per_page: 15,
		current_page: 1,
		data: [],
	},
})

let state = initialState()
let mutations = createMutations('SET', 'PUSH', 'UPDATE', 'DELETE')

let getters = {
	$stores: (state) => state.stores,
	$drivers: (state) => state.drivers,
	$companies: (state) => state.companies,

	$storeInvoices: (state) => state.storeInvoices,
	$companyInvoices: (state) => state.companyInvoices,

	$adminInvoices: (state) => state.adminInvoices,
	$driversInvoice: (state) => state.driversInvoice,
	$driversInvoices: (state) => state.driversInvoices,
}

let actions = {
	getAdminInvoices({ commit, state }, payload) {
		return handleAction(
			handleRequest(api.get('/api/invoice/company', { params: payload })),
			(response) => {
				let isExist = state.adminInvoices.some(
					(invoice) => invoice.company_id === payload.company_id
				)
				if (isExist) {
					commit('UPDATE', [
						'adminInvoices',
						{
							company_id: payload.company_id,
							invoices: response,
						},
						'company_id',
					])
				} else
					commit('PUSH', [
						'adminInvoices',
						{
							company_id: payload.company_id,
							invoices: response,
						},
					])
			}
		)
	},
	getStoreInvoices({ commit, state }, payload) {
		if (
			state.storeInvoices.some(
				(invoice) =>
					invoice.store_id === payload.store_id &&
					invoice.start_date === payload.start_date &&
					invoice.end_date === payload.end_date
			)
		) {
			return
		}

		return handleAction(
			handleRequest(api.get('/api/invoice/store', { params: payload })),
			(response) => {
				commit('PUSH', [
					'storeInvoices',
					{
						store_id: payload.store_id,
						start_date: payload.start_date,
						end_date: payload.end_date,
						invoices: response,
					},
				])
			}
		)
	},
	getCompanyInvoices({ commit, state }, payload) {
		if (
			state.companyInvoices.some(
				(invoice) =>
					invoice.company_id === payload.company_id &&
					invoice.start_date === payload.start_date &&
					invoice.end_date === payload.end_date
			)
		) {
			return
		}

		return handleAction(
			handleRequest(api.get('/api/invoice/company', { params: payload })),
			(response) => {
				commit('PUSH', [
					'companyInvoices',
					{
						company_id: payload.company_id,
						start_date: payload.start_date,
						end_date: payload.end_date,
						invoices: response,
					},
				])
			}
		)
	},
	updateStoreInvoice({ commit }, payload) {
		return handleAction(
			handleRequest(api.post('/api/invoice/make-payment-complete', payload)),
			(response) => {
				commit('SET', {
					storeInvoices: (invoices) =>
						invoices.map((invoice) => {
							if (
								invoice.store_id === payload.store_id &&
								invoice.start_date === payload.start_date &&
								invoice.end_date === payload.end_date
							) {
								return {
									store_id: payload.store_id,
									start_date: payload.start_date,
									end_date: payload.end_date,
									invoices: response,
								}
							}
							return invoice
						}),
				})
			}
		)
	},
	getCompanyStores({ commit, state }, payload) {
		let { per_page, current_page, shop_name = '' } = payload || state.stores
		return handleAction(
			handleRequest(
				api.get(`/api/stores/index`, {
					params: {
						per_page,
						shop_name,
						page: current_page,
					},
				})
			),
			({ stores }) => commit('SET', { stores })
		)
	},

	// START DRIVERS
	getCompanyDrivers({ commit, state }, payload) {
		let { per_page, current_page, name = '' } = payload || state.drivers
		return handleAction(
			handleRequest(
				api.get(`/api/drivers/index`, {
					params: {
						per_page,
						name,
						page: current_page,
					},
				})
			),
			({ drivers }) => commit('SET', { drivers })
		)
	},
	getDriversInvoice({ commit, state }, payload) {
		return handleAction(
			handleRequest(api.get('/api/invoice/driver', { params: payload })),
			(response) => {
				let isExist = state.driversInvoices.some((invoice) => {
					return isSameInvoice(invoice, payload, 'driver_id')
				})
				let newInvoice = {
					driver_id: payload.driver_id,
					start_date: payload.start_date,
					end_date: payload.end_date,
					invoices: response,
				}
				if (!isExist) commit('PUSH', ['driversInvoices', newInvoice])
				else
					commit('SET', {
						driversInvoices: (invoices) =>
							invoices.map((invoice) =>
								isSameInvoice(invoice, payload) ? newInvoice : invoice
							),
					})
			}
		)
	},
	// END DRIVERS

	getCompanies({ commit, state }, payload = {}) {
		let { per_page, current_page, name = '' } = payload || state.companies
		return handleAction(
			handleRequest(
				api.get(`/api/companies/index`, {
					params: {
						name,
						per_page,
						page: current_page,
					},
				})
			),
			({ companies }) => {
				commit('SET', { companies })
			}
		)
	},
}

const isSameInvoice = (source, input, key) => {
	return (
		source[key] === input[key] &&
		source.start_date === input.start_date &&
		source.end_date === input.end_date
	)
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}
