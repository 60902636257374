import Vue from 'vue'
import dayjs from './dayjs'
import store from './store'

import get from 'lodash/get'
import omit from 'lodash/omit'

dayjs.extend(require('dayjs/plugin/relativeTime'))

import { ROLES, PAGES, SERVER_BASE_URL } from './consts'

Vue.prototype._ = {
	get,
	omit,
}

Vue.prototype.$m = dayjs
Vue.prototype.$user = store.getters.user
Vue.prototype.$consts = {
	$roles: ROLES,
	$pages: PAGES,
}

Vue.prototype.$utils = {
    $storage(path) {
		if (!path || path.includes('img/placeholder'))
			return require('@/assets/img/placeholder.svg')

		if (['http', 'blob'].some((v) => path.startsWith(v))) return path

		return `${SERVER_BASE_URL}/storage/${path}`
	},
	$commafy(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	},
}
