import axios from 'axios'
import api from '@/api'

const state = {
	stores: [],
	currentPage: 1,
	itemsPerPage: 10,
	totalItems: 0,
	headers: {},
}

const getters = {}

const mutations = {
	SET_ORDERS(state, orders) {
		state.orders = orders
	},
	CORRENT_ORDER(state, order) {
		state.orders = order
	},
	SET_TOTAL_ITEMS(state, totalItems) {
		state.totalItems = totalItems
	},
	SET_CURRENT_PAGE(state, page) {
		state.currentPage = page
	},
	SET_CURRENT_HEADERS(state, header) {
		state.baseUrl = header.baseUrl
		const token = btoa(`${header.appKey}:${header.appSecret}`)

		state.headers = {
			Authorization: `Basic ${token}`,
		}
	},
}

const actions = {
	FetchWooCommerceOrders({ commit, state }, payload) {
		if (!payload.header) return new Error('Token is required for this store!')
		state.baseUrl = null
		state.headers = {}
		commit('SET_CURRENT_HEADERS', payload.header)
		if (!state.baseUrl)
			return new Error('Store Base URL is required for this store')
		return new Promise((resolve, reject) => {
			if (!state.headers) {
				return reject(new Error('Token is required for this store!'))
			}

			let url = `${state.baseUrl}/wp-json/wc/v3/orders`
			if (payload.params) {
				url = `${url}?${payload.params}`
			}

			axios
				.get(url, {
					headers: { ...state.headers },
				})
				.then((res) => {
					const orders = res.data
					commit('SET_ORDERS', orders)
					resolve(orders)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	SingleWooCommerceOrder({ commit, state }, orderId) {
		if (!state.baseUrl)
			return new Error('Store Base URL is required for this store')
		return new Promise((resolve, reject) => {
			const url = `${state.baseUrl}/wp-json/wc/v3/orders/${orderId}`

			axios
				.get(url, {
					headers: { ...state.headers },
				})
				.then((res) => {
					const order = res.data
					commit('CORRENT_ORDER', order)
					resolve(order)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	SyncWooCommerceOrder({ commit }, order) {
		return new Promise((resolve, reject) => {
			api.post('/api/orders/create', order)
				.then((res) => {
					commit('CORRENT_ORDER', res)
					resolve(res)
				})
				.catch((err) => reject(err))
		})
	},
	async UpdateWooCommerceOrder({ commit, state }, orderId) {
		if (!state.baseUrl)
			return new Error('Store Base URL is required for this store')
		return new Promise((resolve, reject) => {
			const url = `${state.baseUrl}/wp-json/wc/v3/orders/${orderId}`
			const data = JSON.stringify({
				status: 'on_delivery',
			})
			let config = {
				method: 'put',
				maxBodyLength: Infinity,
				url: url,
				headers: {
					'Content-Type': 'application/json',
					...state.headers,
				},
				data: data,
			}

			axios
				.request(config)
				.then((res) => {
					const order = res.data
					commit('CORRENT_ORDER', order)
					resolve(order)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
