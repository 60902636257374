import api from '../../api'
import { createGetters, createPaginaion } from 'vuelpers'

const state = () => ({
	products: createPaginaion(),
})

const getters = {
	...createGetters({
		products: (state, _getter, _rootState, _rootGetters) => ({
			...state.products,
			data: state.products.data.map((product) => ({
				...product,
			})),
		}),
	}),
}
const mutations = {
	PRODUCTS_DATA(state, product) {
		state.products = product
	},
}
const actions = {
	fetchProducts({ commit }, params = {}) {
		return new Promise((resolve) => {
			api.get(`/api/product`, { params })
				.then((res) => {
					commit('PRODUCTS_DATA', res.data.products)
					resolve([false, res])
				})
				.catch((err) => resolve([true, err.response || err]))
		})
	},
	createProduct(_, product) {
		return new Promise((resolve, reject) => {
			api.post('/api/product', product)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateProduct(_, payload) {
		return new Promise((resolve, reject) => {
			console.log(
				'🚀 ~ file: product.js:73 ~ updateProduct ~ payload:',
				payload
			)
			api.post(`/api/product/update`, payload)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	deleteProduct(_, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/product/${id}`)
				.then((res) => {
					resolve([false, res])
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
