import store from '@/store'

import { ROLES } from '@/consts'
import { getRoutePathWithRole } from './getRoutePathWithRole'

export const DEFAULT_PAGE = {
	[ROLES.ADMIN]: '/orders',
	[ROLES.COMPANY]: '/order-track',
	[ROLES.OPERATOR]: '/order-track',
	[ROLES.SUPERVISOR]: '/order-track',
	[ROLES.STORE]: '/orders',
	[ROLES.DRIVER]: '/orders',
}

export const getDefaultPage = (fallback = '/') => {
	return getRoutePathWithRole(
		DEFAULT_PAGE[
			store.getters.user.roleSlugs.find((role) => {
				return DEFAULT_PAGE.hasOwnProperty(role)
			})
		] || fallback
	)
}
