import * as dayjs from 'dayjs'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import * as updateLocale from 'dayjs/plugin/updateLocale'
import * as advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(advancedFormat)

dayjs.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'a few secs',
		m: 'a min',
		mm: '%dmins',
		h: 'an hr',
		hh: '%dhrs',
		d: 'a day',
		dd: '%ddays',
		M: 'a mth',
		MM: '%dmths',
		y: 'a yr',
		yy: '%dyrs',
	},
})

export const $m = dayjs

export { dayjs }
export default dayjs
