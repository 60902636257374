import api from '../../api'
import {
	convertKeysToCamelCase,
	createGetters,
	createPaginaion,
} from 'vuelpers'

const state = () => ({
	brands: createPaginaion(),
})

const getters = {
	...createGetters({
		brands: (state, _getter, _rootState, _rootGetters) => ({
			...state.brands,
			data: state.brands.data.map((brand) => ({
				...brand,
			})),
		}),
	}),
}
const mutations = {
	FETCH_BRANDS(state, brands) {
		state.brands = brands
	},
}
const actions = {
	fetchBrands({ commit }, params = {}) {
		return new Promise((resolve) => {
			api.get(`/api/brand`, { params })
				.then((res) => {
					commit('FETCH_BRANDS', res.data.brands)
					resolve([false, res])
				})
				.catch((err) => resolve([true, err.response || err]))
		})
	},
	createBrand(_, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/brand', payload)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateBrand(_, payload) {
		return new Promise((resolve, reject) => {
			api.patch(`/api/brand/update/${payload.id}`, payload)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	deleteBrand(_, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/brand/${id}`)
				.then((res) => {
					resolve([false, res])
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
