import { toNumber } from 'lodash'
import api from '../../api'

let state = {
	allOrdersData: [],
	allAnalyticData: '',
	lifeTimeData: '',
	driversTrackingData: '',
	completedOrdersData: '',
	codOrdersData: '',
	cancelledOrdersData: '',
	deliveredOrdersData: [],
	companydashboard: '',
	admindashboard: '',
	operatordashboard: '',
	storesdashboard: '',
	driverdashboard: '',
	status: '',
}

let getters = {
	allAnalyticData: (state) => state.allAnalyticData,
	lifeTimeData: (state) => state.lifeTimeData,
	allOrdersData: (state) => state.allOrdersData,
	driversTrackingData: (state) => state.driversTrackingData,
	completedOrdersData: (state) => state.completedOrdersData,
	codOrdersData: (state) => state.codOrdersData,
	cancelledOrdersData: (state) => state.cancelledOrdersData,
	deliveredOrdersData: (state) => state.deliveredOrdersData,
	companydashboard: (state) => state.companydashboard,
	admindashboard: (state) => state.admindashboard,
	operatordashboard: (state) => state.operatordashboard,
	driverdashboard: (state) => state.driverdashboard,
	storesdashboard: (state) => state.storesdashboard,
}

let actions = {
	fetchLifeTimeData({ commit }) {
		return new Promise((resolve, reject) => {
			api.get('/api/analytic/lifetime-data').then((response) => {
				commit('LIFE_TIME_DATA', response.data)
			})
		})
	},
	fetchAllOrdersData({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/analytic/orders-tracking', params)
				.then((response) => {
					commit('ALL_ORDERS_DATA', response.data.orders_count)
				})
				.catch((error) => {})
		})
	},
	fetchDriversTracking({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/analytic/drivers-tracking', params).then((response) => {
				commit('DRIVERS_TRACKING_DATA', response.data.drivers_tracking)
			})
		})
	},
	fetchCompletedOrders({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/analytic/completed-orders', params).then((response) => {
				commit('COMPLETED_ORDERS_DATA', response.data.completed_orders)
			})
		})
	},
	fetchCodOrders({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/analytic/cod-orders', params).then((response) => {
				commit('COD_ORDERS_DATA', response.data.cod_orders)
			})
		})
	},
	fetchCancelledOrders({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/analytic/cancelled-orders', params).then((response) => {
				commit('CANCELLED_ORDERS_DATA', response.data.canceled_orders)
			})
		})
	},
	fetchAllAnalytics({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/analytic', params).then((response) => {
				commit('ALL_ANALYTIC_DATA', response.data)
			})
		})
	},
	fetchDeliveredOrders({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/analytic/delivered-orders', params).then((response) => {
				commit('DELIVERED_ORDERS_DATA', response.data.delivered_orders)
			})
		})
	},
	// CompanyDashboardData({ commit }) {
	// 	return new Promise((resolve, reject) => {
	// 		api.get('/api/company-dashboard-analytic')
	// 			.then((res) => {
	// 				const companydashboard = res.data
	// 				commit('COMPANY_DASHBOARD_DATA', companydashboard), resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	// AdminDashboardData({ commit }) {
	// 	return new Promise((resolve, reject) => {
	// 		api.get('/api/admin-dashboard-analytic')
	// 			.then((res) => {
	// 				const admindashboard = res.data
	// 				commit('ADMIN_DASHBOARD_DATA', admindashboard), resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	// OperatorDashboardData({ commit }) {
	// 	return new Promise((resolve, reject) => {
	// 		api.get('/api/operator-dashboard-analytic')
	// 			.then((res) => {
	// 				const operatordashboard = res.data
	// 				commit('OPERATOR_DASHBOARD_DATA', operatordashboard),
	// 					resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	// StoresDashboardData({ commit }) {
	// 	return new Promise((resolve, reject) => {
	// 		api.get('/api/store-dashboard-analytic')
	// 			.then((res) => {
	// 				const storesdashboard = res.data
	// 				commit('STORES_DASHBOARD_DATA', storesdashboard), resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	// DriverDashboardData({ commit }) {
	// 	return new Promise((resolve, reject) => {
	// 		api.get('/api/driver-dashboard-analytic')
	// 			.then((res) => {
	// 				const driverdashboard = res.data
	// 				commit('DRIVER_DASHBOARD_DATA', driverdashboard), resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
}

let mutations = {
	LIFE_TIME_DATA(state, data) {
		state.status = 'success'
		state.lifeTimeData = data
	},
	ALL_ORDERS_DATA(state, data) {
		state.status = 'success'
		state.allOrdersData = data
	},
	DRIVERS_TRACKING_DATA(state, data) {
		state.status = 'success'
		state.driversTrackingData = data
	},
	COMPLETED_ORDERS_DATA(state, data) {
		data = {
			...data,
			total_percentage: data.total > 0 ? '100.00' : '0.00',
			delivered_count_percentage:
				+data.delivered_count > 0
					? ((data.delivered_count / data.total) * 100).toFixed(2)
					: '0.00',
			returned_count_percentage:
				+data.returned_count > 0
					? ((data.returned_count / data.total) * 100).toFixed(2)
					: '0.00',
		}
		state.status = 'success'
		state.completedOrdersData = data
	},
	COD_ORDERS_DATA(state, data) {
		data = {
			...data,
			total_percentage: data.total > 0 ? '100.00' : '0.00',
			delivered_count_percentage:
				+data.delivered_count > 0
					? ((data.delivered_count / data.total) * 100).toFixed(2)
					: '0.00',
			returned_count_percentage:
				+data.returned_count > 0
					? ((data.returned_count / data.total) * 100).toFixed(2)
					: '0.00',
		}
		state.status = 'success'
		state.codOrdersData = data
	},
	CANCELLED_ORDERS_DATA(state, data) {
		data = {
			...data,
			total_percentage: data.total > 0 ? '100.00' : '0.00',
			general_canceled_count_percentage:
				+data.general_canceled_count > 0
					? ((data.general_canceled_count / data.total) * 100).toFixed(2)
					: '0.00',
			cod_canceled_count_percentage:
				+data.cod_canceled_count > 0
					? ((data.cod_canceled_count / data.total) * 100).toFixed(2)
					: '0.00',
		}
		state.status = 'success'
		state.cancelledOrdersData = data
	},
	DELIVERED_ORDERS_DATA(state, data) {
		state.status = 'success'
		state.deliveredOrdersData = data
	},
	ALL_ANALYTIC_DATA(state, data) {
		state.status = 'success'
		state.allAnalyticData = data
	},
	// COMPANY_DASHBOARD_DATA(state, companydashboard) {
	// 	state.status = 'success'
	// 	state.companydashboard = companydashboard
	// },
	// ADMIN_DASHBOARD_DATA(state, admindashboard) {
	// 	state.status = 'success'
	// 	state.admindashboard = admindashboard
	// },
	// OPERATOR_DASHBOARD_DATA(state, operatordashboard) {
	// 	state.status = 'success'
	// 	state.operatordashboard = operatordashboard
	// },
	// STORES_DASHBOARD_DATA(state, storesdashboard) {
	// 	state.status = 'success'
	// 	state.storesdashboard = storesdashboard
	// },
	// DRIVER_DASHBOARD_DATA(state, driverdashboard) {
	// 	state.status = 'success'
	// 	state.driverdashboard = driverdashboard
	// },
}

export default {
	state,
	getters,
	actions,
	mutations,
}
