import store from '@/store'

const getRoutePathWithRole = (path, fallback = path) => {
	if (!store?.getters.user.isLoggedIn) return fallback
	return (
		'/' +
		[store.getters.user.role, ...path.split('/').filter(Boolean)].join('/')
	)
}

export { getRoutePathWithRole }
