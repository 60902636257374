import Vuex from 'vuex'
import Vue from 'vue'
import auth from './modules/auth'
import admin from './modules/admin'
import operator from './modules/operator'
import supervisors from './modules/supervisors'
import pusher from './modules/pusher'
import PickupPoint from './modules/PickupPoint'
import stores from './modules/stores'
import zid from './modules/zid'
import woocommerce from './modules/woocommerce'
import Driver from './modules/driver'
import order from './modules/order'
import teams from './modules/teams'
import $teams from './modules/teams.store'
import user from './modules/user'
import invoices from './modules/invoices'
import arrearInvoice from './modules/arrearInvoice'
import bolesaWallet from './modules/bolesaWallet'
import analyticData from './modules/analyticData'
import product from './modules/product'
import category from './modules/category'
import brand from './modules/brand'
import slider from './modules/slider'
import webPage from './modules/webPage'

import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

import { DOMAIN } from '@/consts'
import areaZone from './modules/areaZone'

// Load Vuex
Vue.use(Vuex)

const plugins = [
	createPersistedState({
		key: `${DOMAIN}`,
		paths: ['auth'],
	}),
]

if (process.env.NODE_ENV === 'development') {
	plugins.push(createLogger())
}

// Create store
export default new Vuex.Store({
	plugins,
	modules: {
		admin,
		product,
		category,
		brand,
		slider,
		webPage,
		auth,
		operator,
		supervisors,
		Driver,
		PickupPoint,
		stores,
		teams,
		order,
		analyticData,
		user,
		zid,
		woocommerce,
		bolesaWallet,
		pusher,
		invoices,
		arrearInvoice,
		areaZone,
		$teams,
	},
})
