import { env } from './helpers'
import colors from 'vuetify/lib/util/colors'

export const ROLES = {
	DRIVER: 'driver',
	STORE: 'store',
	ADMIN: 'admin',
	COMPANY: 'company',
	OPERATOR: 'operator',
	SUPERVISOR: 'supervisor',
}

export const DOMAIN = env('DOMAIN')
export const API_BASE_URL = env('API_BASE_URL')
export const SERVER_BASE_URL = env('SERVER_BASE_URL')
export const DEFAULT_PER_PAGE = env('PER_PAGE') || 10

export const ZID_OAUTH_URL = `${location.origin}${env('BASE', '')}zid/oauth`
export const ZID_OAUTH_REDIRECT_URL = `${ZID_OAUTH_URL}/redirect`
export const ZID_OAUTH_CALLBACK_URL = `${ZID_OAUTH_URL}/callback`

export const THEME_PATH = {
	[ROLES.COMPANY]: 'theme',
	[ROLES.STORE]: 'store.companies.0.user.theme',
	[ROLES.DRIVER]: 'driver.company_driver.0.user.theme',
}

export const DriverStatus = {
	Active: 1,
	Inactive: 2,
	Deleted: 3,
}

export const OrderStatus = {
	Created: 'created',
	AssignPending: 'assign_pending',
	Assigned: 'assigned',
	PickedUp: 'picked_up',
	OnDelivery: 'on_delivery',
	Delivered: 'delivered',
	ToReturn: 'to_return',
	Returned: 'returned',
	Canceled: 'canceled',
}

export const StatusColor = {
	[OrderStatus.Created]: colors.blue.base,
	[OrderStatus.AssignPending]: colors.lightBlue.base,
	[OrderStatus.Assigned]: colors.indigo.base,
	[OrderStatus.PickedUp]: colors.cyan.base,
	[OrderStatus.OnDelivery]: colors.teal.base,
	[OrderStatus.Delivered]: colors.green.base,
	[OrderStatus.ToReturn]: colors.brown.base,
	[OrderStatus.Returned]: colors.pink.base,
	[OrderStatus.Canceled]: colors.red.base,
}

export const Channel = {
	ActiveUser(userId) {
		return `active-user.${userId}`
	},
	Notifications(userId) {
		return `notifications.${userId}`
	},
}

export const Event = {
	// ActiveUser
	OnActiveUser: '.OnActiveUser',
	OnUpdateUser: '.OnUpdateUser',

	// Notifications
	OnLocationTrack: '.OnLocationTrack',
	OnCreateNewOrder: '.OnCreateNewOrder',
	OnUpdateOrderStatus: '.OnUpdateOrderStatus',
	OnUpdateSMSAOrder: '.GenerateSMSAOrderEvent',
}

export const orderStatuses = [
	{
		name: 'Created',
		value: OrderStatus.Created,
	},
	{
		name: 'Assign Pending',
		value: OrderStatus.AssignPending,
	},
	{
		name: 'Assigned',
		value: OrderStatus.Assigned,
	},
	{
		name: 'Picked Up',
		value: OrderStatus.PickedUp,
	},
	{
		name: 'On Delivery',
		value: OrderStatus.OnDelivery,
	},
	{
		name: 'Delivered',
		value: OrderStatus.Delivered,
	},
	{
		name: 'To Return',
		value: OrderStatus.ToReturn,
	},
	{
		name: 'Returned',
		value: OrderStatus.Returned,
	},
	{
		name: 'Canceled',
		value: OrderStatus.Canceled,
	},
]

export const PAGES = {
	LOGIN: 'LoginPage',
	ORDER_TRACKING: 'TrackingPage',
	FORGOT_PASSWORD: 'ForgotPasswordPage',
	CHANGE_PASSWORD: 'ChangePasswordPage',

	PROFILE: 'ProfilePage',
	SETTINGS: 'SettingsPage',

	ANALYTICS: 'AnalyticsPage',
	ORDER_TRACK: 'OrderTrackPage',

	STORES: 'StoresPage',
	ADMIN_STORES: 'AdminStoresPage',

	ALL_ORDERS: 'AllOrdersPage',
	PLATFORM_ORDERS: 'platformOrdersPage',
    ALL_PRODUCTS: 'AllProductsPage',
    BRANDS: 'BrandsPage',
    SLIDERS: 'SlidersPage',
	WEB_PAGES: 'WebPage',
	ALL_CATEGORIES: 'AllCategoriesPage',
	ADMIN_ORDERS: 'AdminOrdersPage',

	PAYMENTS: 'PaymentsPage',
	DRIVERS: 'DriversPage',
	ADMIN_DRIVERS: 'AdminDriversPage',

	INVOICES: 'InvoicesPage',

	COUNTRIES: 'CountriesPage',

	TEAMS: 'TeamsPage',
	ADMIN_TEAMS: 'AdminTeamsPage',

	// Company Wallet Page
	WALLET: 'BolesaWalletPage',
	ADMIN_WALLET: 'AdminCompanyBolesaPage',

	// Company Arrear Page
	// ARREAR: 'CompanyArrearPage',
	ARREAR: 'ArrearInvoicePage',
	ADIMN_ARREAR: 'AdminCompanyArrearPage',

	// ZID
	ZID_CALLBACK: 'ZidCallbackPage',
	ZID_OAUTH_REDIRECT: 'ZidOAuthRedirectPage',
	ZID_OAUTH_CALLBACK: 'ZidOAuthCallbackPage',
	ZID_ORDER_VIEW: 'ZidOrderViewPage',

	// SIGNUP
	COMPANY_SIGNUP: 'CompanySignupPage',
	STORE_SIGNUP: 'StoreSignupPage',
	DRIVER_SIGNUP: 'DriverSignupPage',

	// ADMIN
	DELIVERY: 'DeliveryPage',
	ADMIN_COMPANIES: 'AdminCompaniesPage',
	ADMIN_VIEW_COMPANY: 'AdminViewCompanyPage',

	// COMPANY
	OPERATORS: 'OperatorsPage',
	SUPERVISORS: 'SupervisorsPage',

	// STORE
	PICKUP_POINTS: 'PickupPointsPage',
	SALLA_CALLBACK: 'SallaCallback',

	NOT_FOUND: 'NotFoundPage',

	CONTRACT_AGREEMENT: 'ContractAgreement',

	ZONE: 'ZonePage',
}
