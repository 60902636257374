import { createMutations, createGetters, MERGE, sleep } from 'vuelpers'

const initialState = () => ({
	onlineUsers: [],
	driverMarkers: [],
	isReady: false,
})

let state = initialState()
let mutations = createMutations('SET', 'PUSH', 'UPDATE', MERGE, 'DELETE')
let newUserIds = []

let getters = createGetters('isReady', {
	onlineDrivers(state, _, __, rootGetters) {
		const onlineDrivers = state.onlineUsers
			.filter((item) => {
				const user = item.user ? item.user : item
				newUserIds.push(user.id)
				newUserIds = [...new Set(newUserIds)]
				let operatorCheck = true
				if (rootGetters.user.isOperator) {
					operatorCheck =
						rootGetters.user.operator.id == user.driver?.operator_id
				}
				return (
					user &&
					user.driver &&
					operatorCheck &&
					user.user_roles &&
					user.user_roles.length &&
					user.user_roles[0].slug === 'driver'
				)
			})
			.map((user) => ({
				...user,
				marker: state.driverMarkers.find((driver) => {
					return driver.user_id === user.id
				}),
			}))
		return onlineDrivers
	},
})

let actions = {
	onOnlineUsers({ commit }, payload) {
		commit('SET', { onlineUsers: payload })
	},
	onChangeDriverLocation({ commit }, payload) {
		const { user, position } = payload

		commit(MERGE, [
			'driverMarkers',
			[
				{
					position,
					user_id: user.id,
					id: user.driver.id,
					phone: user.phone,
					email: user.email,
					full_name: user.name,
				},
			],
		])
	},
	onOnlineUser({ commit, state }, payload) {
		const user = payload.user ? payload.user : payload
		newUserIds.push(user.id)
		newUserIds = [...new Set(newUserIds)]

		commit(MERGE, ['onlineUsers', [user]])
	},
	onOfflineUser({ commit }, payload) {
		newUserIds = newUserIds.filter(function (e) {
			return e != payload.id
		})

		sleep(3000).then(() => {
			if (!newUserIds.includes(payload.id)) {
				commit('DELETE', ['onlineUsers', payload.id])
			}
		})
	},
	resetPusherState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}
