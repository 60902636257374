import api from '../../api'

import {
	createGetters,
	convertKeysToCamelCase,
	createPaginaion as createPagination,
} from 'vuelpers'

const state = () => ({
	teams: createPagination(),
})

const getters = createGetters('teams')
const mutations = {
	CREATE_TEAMS(state, payload) {
		state.teams.total += 1
		state.teams.data.unshift(convertKeysToCamelCase(payload.team))
	},
	TEAMS_DATA(state, teams) {
		state.teams = teams
	},
	TEAMS_UPDATE(state, teamsData) {
		const index = state.teams.data.findIndex((teams) => {
			return teams.id === teamsData.id
		})
		if (index !== -1) {
			state.teams.data.splice(index, 1, teamsData)
		}
	},
	TEAMS_STATUS_UPDATE(state, payload) {
		const index = state.teams.data.findIndex((teams) => {
			return teams.id === payload.id
		})
		if (index !== -1) {
			state.teams.data.splice(index, 1, {
				...state.teams.data[index],
				payload,
			})
		}
	},
	TEAMS_DELETE(state, id) {
		state.teams.total -= 1
		state.teams.data = state.teams.data.filter((teams) => {
			return teams.id !== id
		})
	},
}
const actions = {
	CreateTeams({ commit }, teams) {
		return new Promise((resolve, reject) => {
			api.post('/api/teams/create', teams)
				.then((res) => {
					if (!res.data.error) {
						const teams = res.data
						commit('CREATE_TEAMS', teams)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	TeamsData({ commit }, params = {}) {
		return new Promise((resolve, reject) => {
			api.get('/api/teams/index', { params })
				.then((res) => {
					commit('TEAMS_DATA', convertKeysToCamelCase(res.data.teams))
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	TeamsUpdate({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.post(`/api/teams/update`, payload)
				.then((res) => {
					commit('TEAMS_UPDATE', res.data)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	Teamsdelete({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/teams/delete/${id}`)
				.then((res) => {
					commit('TEAMS_DELETE', id)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	TeamsUpdateStatus(_, payload) {
		return new Promise((resolve, reject) => {
			api.post(`/api/teams/status-update`, payload)
				.then((res) => {
					// commit('TEAMS_STATUS_UPDATE', payload)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
