import { VTextField as e } from 'vuetify/lib'
'undefined' != typeof window &&
	((window.vgaMapState = { initMap: !1 }),
	(window.initVGAMaps = () => {
		window.vgaMapState.initMap = !0
	}))
let isExactSame = (e, t) => JSON.stringify(e) === JSON.stringify(t),
	VuetifyGoogleAutocomplete = {
		name: 'vuetify-google-autocomplete',
		props: {
			addressComponents: {
				type: Object,
				default: () => ({
					street_number: 'short_name',
					route: 'long_name',
					locality: 'long_name',
					administrative_area_level_1: 'short_name',
					administrative_area_level_2: 'short_name',
					country: 'long_name',
					postal_code: 'short_name',
				}),
			},
			appendIcon: { type: String, default: void 0 },
			appendOuterIcon: { type: String, default: void 0 },
			autocapitalize: { type: String, default: 'off' },
			autocorrect: { type: String, default: 'off' },
			autofocus: { type: Boolean, default: !1 },
			backgroundColor: { type: String, default: void 0 },
			browserAutocomplete: { type: String, default: 'off' },
			clearIcon: { type: String, default: '$clear' },
			clearable: { type: Boolean, default: !1 },
			color: { type: String, default: void 0 },
			counter: { type: [Boolean, Number, String], default: void 0 },
			country: { type: [String, Array], default: null },
			dark: { type: Boolean, default: !1 },
			dense: { type: Boolean, default: !1 },
			disabled: { type: Boolean, default: !1 },
			enableGeolocation: { type: Boolean, default: !1 },
			error: { type: Boolean, default: !1 },
			errorCount: { type: [Number, String], default: 1 },
			errorMessages: { type: Array, default: () => [] },
			filled: { type: Boolean, default: !1 },
			fields: { type: [String, Array], default: null },
			flat: { type: Boolean, default: !1 },
			fullWidth: { type: Boolean, default: !1 },
			hideDetails: { type: [Boolean, String], default: !1 },
			hint: { type: String, default: void 0 },
			id: { type: String, required: !0 },
			label: { type: String, default: void 0 },
			light: { type: Boolean, default: !1 },
			loaderHeight: { type: [Number, String], default: 2 },
			loading: { type: [Boolean, String], default: !1 },
			messages: { type: [String, Array], default: () => [] },
			mask: { type: [Object, String], default: void 0 },
			noResize: { type: Boolean, default: !1 },
			outlined: { type: Boolean, default: !1 },
			persistentPlaceholder: { type: Boolean, default: !1 },
			persistentHint: { type: Boolean, default: !1 },
			placeholder: { type: String, default: void 0 },
			placeName: { type: Boolean, default: !1 },
			prefix: { type: String, default: void 0 },
			prependIcon: { type: String, default: void 0 },
			prependIconInner: { type: String, default: void 0 },
			readonly: { type: Boolean, default: !1 },
			returnMaskedValue: { type: Boolean, default: !1 },
			reverse: { type: Boolean, default: !1 },
			rounded: { type: Boolean, default: !1 },
			rows: { type: [Number, String], default: 5 },
			rules: { type: Array, default: () => [] },
			selectionRequired: { type: Boolean, default: !1 },
			selectionRequiredText: {
				type: String,
				default: 'Please select an address from the list.',
			},
			shaped: { type: Boolean, default: !1 },
			singleLine: { type: Boolean, default: !1 },
			solo: { type: Boolean, default: !1 },
			soloInverted: { type: Boolean, default: !1 },
			spellcheck: { type: String, default: 'false' },
			suffix: { type: String, default: void 0 },
			success: { type: Boolean, default: !1 },
			successMessages: { type: [String, Array], default: () => [] },
			tabindex: { default: 0 },
			textarea: { type: Boolean, default: !1 },
			toggleKeys: { type: Array, default: () => [13, 32] },
			type: { type: String, default: 'text' },
			types: { type: [String, Array], default: () => [] },
			validateOnBlur: { type: Boolean, default: !1 },
			value: { type: String, default: void 0, required: !1 },
		},
		components: { VTextField: e },
		data: () => ({
			autocomplete: null,
			autocompleteText: '',
			geolocateSet: !1,
			loadInterval: null,
			vgaMapState: null,
			lastSelectedPlace: '',
		}),
		computed: {
			rulesPlusInternalRules() {
				let e = []
				return (
					this.selectionRequired &&
					('' === this.lastSelectedPlace.trim() ||
						this.lastSelectedPlace !== this.autocompleteText)
						? e.push(this.selectionRequiredText)
						: e.push(!0),
					[...this.rules, ...e]
				)
			},
		},
		methods: {
			onFocus() {
				this.geolocate(), this.$emit('focus')
			},
			onBlur() {
				this.$emit('blur')
			},
			onChange() {
				this.$emit('change', this.autocompleteText)
			},
			onClickAppend() {
				this.$emit('click:append')
			},
			onClickAppendOuter() {
				this.$emit('click:append-outer')
			},
			onClickClear() {
				this.$emit('click:clear')
			},
			onClickPrepend() {
				this.$emit('click:prepend')
			},
			onClickPrependInner() {
				this.$emit('click:prepend-inner')
			},
			onUpdateError() {
				this.$emit('update:error')
			},
			onKeyPress(e) {
				this.$emit('keypress', e)
			},
			clear() {
				;(this.autocompleteText = ''),
					this.$refs.textField.clearableCallback()
			},
			focus() {
				this.$refs.autocomplete.focus()
			},
			blur() {
				this.$refs.autocomplete.blur()
			},
			update(e) {
				this.autocompleteText = e
			},
			geolocate() {
				this.enableGeolocation &&
					!this.geolocateSet &&
					navigator.geolocation &&
					navigator.geolocation.getCurrentPosition((e) => {
						let t = { lat: e.coords.latitude, lng: e.coords.longitude }
						this.setupGmapApi(() => {
							let o = new window.google.maps.Circle({
								center: t,
								radius: e.coords.accuracy,
							})
							this.autocomplete.setBounds(o.getBounds()),
								(this.geolocateSet = !0)
						})
					})
			},
			getTypes() {
				return Array.isArray(this.types) ? this.types : [this.types]
			},
			setupGoogle() {
				let e = {}
				this.types && (e.types = this.getTypes()),
					this.country &&
						(e.componentRestrictions = { country: this.country }),
					this.fields &&
						('string' == typeof this.fields
							? (e.fields = [this.fields])
							: (e.fields = this.fields)),
					this.setupGmapApi(() => {
						;(this.autocomplete =
							new window.google.maps.places.Autocomplete(
								document.getElementById(this.id),
								e
							)),
							this.autocomplete.addListener('place_changed', () => {
								let e = this.autocomplete.getPlace()
								if (Object.keys(e).length < 2) {
									this.$emit('no-results-found', e)
									return
								}
								let t = {}
								if (
									(void 0 !== e.name && this.placeName
										? (this.autocompleteText = e.name)
										: void 0 !== e.formatted_address &&
										  (this.autocompleteText = e.formatted_address),
									void 0 !== e.address_components)
								) {
									for (
										let o = 0;
										o < e.address_components.length;
										o += 1
									) {
										let l = e.address_components[o].types[0]
										if (this.addressComponents[l]) {
											let a =
												e.address_components[o][
													this.addressComponents[l]
												]
											t[l] = a
										}
									}
									e.geometry &&
										((t.latitude = e.geometry.location.lat()),
										(t.longitude = e.geometry.location.lng())),
										e.name && (t.name = e.name),
										e.photos && (t.photos = e.photos),
										e.place_id && (t.place_id = e.place_id),
										this.$emit('placechanged', t, e, this.id),
										(this.lastSelectedPlace = this.autocompleteText),
										this.onChange(),
										this.validateOnBlur &&
											this.$refs.textField.validate()
								}
							})
					}),
					document
						.getElementById(this.id)
						.setAttribute(
							'placeholder',
							this.placeholder ? this.placeholder : ''
						)
			},
			setupGmapApi(e) {
				this.$vueGoogleMapsCompatibility
					? Object.prototype.hasOwnProperty.call(
							this,
							'$gmapApiPromiseLazy'
					  ) && this.$gmapApiPromiseLazy().then(() => e())
					: Object.prototype.hasOwnProperty.call(window, 'google') && e()
			},
		},
		created() {
			;(this.autocompleteText = this.value ? this.value : ''),
				(this.lastSelectedPlace = this.autocompleteText)
		},
		mounted() {
			;(this.vgaMapState = window.vgaMapState),
				this.setupGmapApi(this.setupGoogle)
		},
		destroyed() {
			window.vgaMapState.initMap = !1
		},
		render(e) {
			let t = this
			return e(
				'v-text-field',
				{
					ref: 'textField',
					attrs: {
						id: t.id,
						name: t.id,
						autocapitalize: t.autocapitalize,
						autocorrect: t.autocorrect,
						spellcheck: t.spellcheck,
					},
					props: {
						'append-icon': t.appendIcon,
						'append-outer-icon': t.appendOuterIcon,
						autofocus: t.autofocus,
						'background-color': t.backgroundColor,
						'browser-autocomplete': t.browserAutocomplete,
						'clear-icon': t.clearIcon,
						clearable: t.clearable,
						color: t.color,
						counter: t.counter,
						dark: t.dark,
						dense: t.dense,
						disabled: t.disabled,
						error: t.error,
						'error-count': t.errorCount,
						'error-messages': t.errorMessages,
						filled: t.filled,
						flat: t.flat,
						'full-width': t.fullWidth,
						'hide-details': t.hideDetails,
						hint: t.hint,
						label: t.label,
						light: t.light,
						'loader-height': t.loaderHeight,
						loading: t.loading,
						messages: t.messages,
						mask: t.mask,
						'no-resize': t.noResize,
						outlined: t.outlined,
						'persistent-hint': t.persistentHint,
						'persistent-placeholder': t.persistentPlaceholder,
						placeholder: t.placeholder,
						prefix: t.prefix,
						'prepend-icon': t.prependIcon,
						'prepend-inner-icon': t.prependIconInner,
						readonly: t.readonly,
						'return-masked-value': t.returnMaskedValue,
						reverse: t.reverse,
						rounded: t.rounded,
						rows: t.rows,
						rules: t.rulesPlusInternalRules,
						ref: 'autocomplete',
						shaped: t.shaped,
						'single-line': t.singleLine,
						solo: t.solo,
						'solo-inverted': t.soloInverted,
						suffix: t.suffix,
						success: t.success,
						'success-messages': t.successMessages,
						tabindex: t.tabindex,
						textarea: t.textarea,
						'toggle-keys': t.toggleKeys,
						type: t.type,
						value: t.autocompleteText,
						'validate-on-blur': t.validateOnBlur,
						'@focus': t.onFocus(),
						'@blur': t.onFocus(),
						'@change': t.onChange(),
						'@click:append': t.onClickAppend(),
						'@click:append-outer': t.onClickAppendOuter(),
						'@click:clear': t.onClickClear(),
						'@click:prepend': t.onClickPrependInner(),
						'@click:prepend-inner': t.onClickPrependInner(),
						'@update:error': t.onUpdateError(),
						'@keypress': t.onKeyPress(),
					},
					domProps: {},
					on: {
						focus() {
							t.onFocus()
						},
						blur() {
							t.onBlur()
						},
						change() {
							t.onChange()
						},
						'click:append'() {
							t.onClickAppend()
						},
						'click:append-outer'() {
							t.onClickAppendOuter()
						},
						'click:clear'() {
							t.onClickClear()
						},
						'click:prepend'() {
							t.onClickPrepend()
						},
						'click:prepend-inner'() {
							t.onClickPrependInner()
						},
						'update:error'() {
							t.onUpdateError()
						},
						keypress(e) {
							t.onKeyPress(e.target.value)
						},
						input: (e) => {
							this.autocompleteText = e
						},
					},
				},
				[
					'append',
					'append-outer',
					'label',
					'message',
					'prepend',
					'prepend-inner',
					'progress',
				].map((t) => e('template', { slot: t }, [this.$slots[t]]))
			)
		},
		watch: {
			autocompleteText: function e(t) {
				this.$emit('input', t || '')
			},
			value: function e(t) {
				t !== this.autocompleteText &&
					((this.autocompleteText = t), (this.lastSelectedPlace = t))
			},
			country(e, t) {
				e &&
					!isExactSame(e, t) &&
					this.autocomplete &&
					this.autocomplete.setComponentRestrictions({ country: e })
			},
			fields: function e(t) {
				t &&
					('string' == typeof t ? this.setFields([t]) : this.setFields(t))
			},
			enableGeolocation: function e(t) {
				t || (this.geolocateSet = !1), (this.enableGeolocation = t)
			},
			'vgaMapState.initMap': function e(t) {
				t && this.setupGmapApi(this.setupGoogle)
			},
			'window.vueGoogleMapsInit': function e() {
				window.vgaMapState.initMap = !0
			},
			types: function e(t) {
				t && this.autocomplete?.setTypes(this.getTypes())
			},
		},
	}
VuetifyGoogleAutocomplete.install = (e, t) => {
	;(t = { installComponents: !0, vueGoogleMapsCompatibility: !1, ...t }),
		e.mixin({
			created() {
				this.$vueGoogleMapsCompatibility = t.vueGoogleMapsCompatibility
			},
		}),
		t.apiKey && t.vueGoogleMapsCompatibility,
		t.installComponents &&
			e.component(VuetifyGoogleAutocomplete.name, VuetifyGoogleAutocomplete)
}
export default VuetifyGoogleAutocomplete
