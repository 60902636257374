import axios from 'axios'
import api from '@/api'

const state = {
	zones: {},
	zone: {},
}

const mutations = {
	AREA_ZONES(state, zones) {
		state.zones = zones
	},
	AREA_ZONE(state, zone) {
		state.zone = zone
	},
	DELETE_AREA_ZONE(state, zoneId) {
		const data = state.zones?.zones?.data.filter((zone) => zone.id != zoneId)
		state.zones.zones.data = data
	},
}

const getters = {
	$areaZones: (state) => state.zones,
	$areaZone: (state) => state.zone,
}

const actions = {
	async CreatingAreaZone({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/area-zone/creating', payload)
				.then((res) => {
					const zone = res.data.zone
					commit('AREA_ZONE', zone)
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	FetchingAreaZones({ commit }) {
		return new Promise((resolve, reject) => {
			api.get('/api/area-zone/fetching')
				.then((res) => {
					const zones = res.data
					commit('AREA_ZONES', zones)
					resolve(zones)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	async UpdatingAreaZone({ commit, state }, orderId) {},
	async DeletingAreaZone({ commit }, zoneId) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/area-zone/deleting/${zoneId}`)
				.then((res) => {
					const zone = res.data
					commit('DELETE_AREA_ZONE', zoneId)
					resolve(zone)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
