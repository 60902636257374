import capitalize from 'lodash/capitalize'

const setDocumentTitleFromPath = (path = '', appends = ['Stapo']) => {
	document.title = [
		...path.split('/').filter(Boolean).map(capitalize).reverse(),
		...appends,
	].join(' - ')
}

export { setDocumentTitleFromPath }
