/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./App.vue?vue&type=template&id=00d07420"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=00d07420&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_lodash@4.17.21_prettier@2.8.8_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports