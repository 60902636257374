import { createPaginaion } from 'vuelpers'
import api from '../../api'

let state = {
	my_store: {},
	stores: createPaginaion(),
}

let getters = {
	stores: (state) => state.stores,
}

let actions = {
	StoreRegistration({ commit }, stores) {
		return new Promise((resolve, reject) => {
			api.post('/api/register-store', stores)
				.then((res) => {
					const stores = res.data
					commit('CREATE_STORES', stores)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	createStores({ commit }, store) {
		return new Promise((resolve, reject) => {
			api.post('/api/stores/create', store)
				.then((res) => {
					if (res.response?.status >= 200 && res.response?.status < 300) {
						return reject(res)
					} else {
						const store = res.data.store
						commit('CREATE_STORES', store)
						return resolve(res)
					}
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	StoresData({ commit }, payload = {}) {
		return new Promise((resolve, reject) => {
			payload = typeof payload === 'number' ? { page: payload } : payload
			api.get('/api/stores/index', { params: payload })
				.then((res) => {
					commit('STORES_DATA', res.data.stores)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	GetStoresList({ commit }, payload = {}) {
		return new Promise((resolve, reject) => {
			payload = typeof payload === 'number' ? { page: payload } : payload
			api.get('/api/stores', { params: payload })
				.then((res) => {
					const stores = res.data?.stores
					commit('STORES_SHORT_DATA', stores)
					resolve(stores)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	SingleStore({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.get(`/api/stores/show/${id}`)
				.then((res) => {
					const store = res.data.store
					commit(`STORE`, store), resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	MyStore({ commit }) {
		return new Promise((resolve, reject) => {
			api.get('/api/stores/my-store')
				.then((res) => {
					const store = res.data.store
					commit('MY_STORE', store), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	StoresUpdate({ commit }, stores) {
		return new Promise((resolve, reject) => {
			api.post('/api/stores/update', stores)
				.then((res) => {
					const storesData = res.data
					commit('STORES_UPDATE', storesData), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	StoresDelete({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/stores/delete/${id}`)
				.then((res) => {
					commit('STORES_DELETE', id), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	GenerateOrderReferenceNumber(_, id) {
		return new Promise((resolve, reject) => {
			api.get(`/api/stores/${id}/generate-reference-number`)
				.then((res) => {
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

let mutations = {
	CREATE_STORES(state, stores) {
		state.status = 'success'
	},
	STORES_DATA(state, stores) {
		state.stores = stores
	},
	STORES_SHORT_DATA(state, stores) {
		state.storesData = stores
	},
	STORE(state, store) {
		state.status = 'success'
		state.store = store
	},
	MY_STORE(state, store) {
		state.status = 'success'
		state.my_store = store
	},
	STORES_UPDATE(state, storesData) {
		state.status = 'success'
		state.stores = storesData

		// const index = state.stores.findIndex(
		//    stores =>stores.id ===storesData.id
		// );
		// if (index !== -1) {
		//     state.stores.splice(index, 1,storesData);
		// }
	},
	STORES_DELETE(state, id) {
		state.status = 'success'
		// state.stores = state.stores.filter(
		//    stores =>stores.id !== id
		// );
		// const index = state.stores.findIndex(stores =>stores.id === storesData.id);
		// state.stores.splice(index, 1,storesData);
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
