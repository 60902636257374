import { orderParser } from '@/helpers/orderParser'
import api from '../../api'
import camelcaseKeys from 'camelcase-keys'
import
{
    createGetters,
    createMutations,
    createPaginaion,
    omitEmpties,
    toFormData,
} from 'vuelpers'

export const initialFilter = (v = {}) => ({
    order_number: '',
    reference_number: '',
    recipient_address: '',
    recipient_contact_name: '',
    pickup_time: [],
    cod_amount: '',
    created_at: '',
    pickup_date: '',
    status: '',
    ...v,
})

const initialOrderUpdate = () => ({
    data: {
        id: null,
        cod_amount: 0,
        driver: { id: null },
        status: { value: null },
    },
    onUpdate: null,
    valid: false,
    loading: false,
    dialog: false,
})

const state = () => ({
    count: {
        created: 0,
        in_progress: 0,
    },
    selectedOrders: [],
    orders: { data: [] },
    shippedOrders: { data: [] },
    NewOrders: { data: [] },
    inProgressOrders: { data: [] },
    assignPendingOrders: { data: [] },
    store_orders: [],
    payments: createPaginaion(),
    status: '',
    refreshOrdersData: false,
    filterData: [],
    orderView: {
        dialog: false,
        data: {
            id: null,
        },
    },
    orderUpdate: initialOrderUpdate(),
    orderLogs: [],
    order: {},
    filter: {
        isLoadingNewOrders: false,
        isPendingFetchNewOrders: false,

        isLoadingInProgressOrders: false,
        isPendingFetchInProgressOrders: false,

        created: initialFilter(),
        progress: initialFilter({ created_at: 'today' }),
    },
})

const getters = {
    ...createGetters('filter', 'payments', 'selectedOrders', {
        queryInProgress({ filter })
        {
            return omitEmpties(filter.progress)
        },
        queryNewOrders({ filter })
        {
            return omitEmpties(filter.created)
        },
    }),
    orders: (state) =>
    {
        return {
            ...state.orders,
            data: state.orders?.data?.map((order) =>
            {
                return {
                    ...order,
                    pickup_address:
                        typeof order.pickup_address === 'string'
                            ? JSON.parse(order.pickup_address)
                            : order.pickup_address,
                    recipient_address:
                        typeof order.recipient_address === 'string'
                            ? JSON.parse(order.recipient_address)
                            : order.recipient_address,
                }
            }),
        }
    },
    shippedOrders: (state) =>
    {
        return {
            ...state.shippedOrders,
            data: state.shippedOrders.data.map((order) =>
            {
                return {
                    ...order,
                    pickup_address:
                        typeof order.pickup_address === 'string'
                            ? JSON.parse(order.pickup_address)
                            : order.pickup_address,
                    recipient_address:
                        typeof order.recipient_address === 'string'
                            ? JSON.parse(order.recipient_address)
                            : order.recipient_address,
                }
            }),
        }
    },
    assignPendingOrders: (state) =>
    {
        return {
            ...state.assignPendingOrders,
            data: state.assignPendingOrders.data.map((order) =>
            {
                return {
                    ...order,
                    pickup_address:
                        typeof order.pickup_address === 'string'
                            ? JSON.parse(order.pickup_address)
                            : order.pickup_address,
                    recipient_address:
                        typeof order.recipient_address === 'string'
                            ? JSON.parse(order.recipient_address)
                            : order.recipient_address,
                }
            }),
        }
    },
    inProgressOrders: (state) =>
    {
        return {
            ...state.inProgressOrders,
            data: state.inProgressOrders.data.map((order) =>
            {
                return {
                    ...order,
                    pickup_address:
                        typeof order.pickup_address === 'string'
                            ? JSON.parse(order.pickup_address)
                            : order.pickup_address,
                    recipient_address:
                        typeof order.recipient_address === 'string'
                            ? JSON.parse(order.recipient_address)
                            : order.recipient_address,
                }
            }),
        }
    },
    ordersInProggress: (_, getters) =>
    {
        return getters.inProgressOrders
    },
    newOrders: (state) =>
    {
        return state.NewOrders
    },
    ordersCreated: (state) =>
    {
        return {
            ...state.NewOrders,
            data: state.NewOrders.data.map(orderParser),
        }
    },
    ordersAssignPending: (_, getters) =>
    {
        return {
            ...getters.orders,
            data: getters.orders.data.filter((order) =>
            {
                return order.status === 'assign_pending'
            }),
        }
    },
    store_orders: (state) => state.store_orders,
    refreshOrdersData: (state) => state.refreshOrdersData,
    getFilterData: (state) => state.filterData,
    orderView: (state) =>
    {
        let logs = state.orderLogs.find(
            (log) => log.order_id === state.orderView.data.id
        )
        state.orderView.data.logs = logs
        return state.orderView
    },
    orderUpdate: (state) => state.orderUpdate,
    orderLogs: (state) => state.orderLogs,
    order: (state) => state.order,
    count: (state) => state.count,
}

let mutations = {
	...createMutations('SET', 'PUSH', 'CONCAT', 'DELETE', 'UPDATE', 'UNSHIFT'),
	setSelectedOrders(state, payload) {
		state.selectedOrders = payload
	},
	setOrderView(state, payload) {
		state.orderView = payload
	},
	setOrderUpdate(state, payload) {
		state.orderUpdate = {
			...state.orderUpdate,
			...payload,
		}
	},
	resetOrderUpdate(state) {
		state.orderUpdate = initialOrderUpdate()
	},
	TOGGLE_REFRESH_ORDERS_DATA(state, payload) {
		state.refreshOrdersData = payload
	},
	CREATE_ORDERS(state, orders) {
		state.orders.unshift(orders)
	},
	ORDERS_DATA(state, orders) {
		state.status = 'success'
		state.orders = orders
	},
	ALL_ORDERS_DATA(state, orders) {
		state.status = 'success'
		state.orders = orders
	},
	SHIPPED_ORDERS_DATA(state, orders) {
		state.status = 'success'
		state.shippedOrders = orders
	},
	ASSIGN_PENDING_DATA(state, orders) {
		state.status = 'success'
		state.assignPendingOrders = orders
	},
	IN_PROGRESS_DATA(state, orders) {
		state.status = 'success'
		state.inProgressOrders = orders
	},
	order(state, order) {
		state.order = order
	},
	ALL_ORDERS_SEARCH_DATA(state, orders) {
		state.status = 'success'
		state.orders = orders
	},
	STORE_ORDERS_DATA(state, store_orders) {
		state.status = 'success'
		state.store_orders = store_orders
	},
	ORDERS_UPDATE(state, ordersData) {
		state.status = 'success'

		const index = state.orders.findIndex(
			(orders) => orders.id === ordersData.id
		)
		if (index !== -1) {
			state.orders.splice(index, 1, ordersData)
		}
	},
	ORDERS_DELETE(state, id) {
		state.status = 'success'
	},
	PAYMENT_DATA(state, payments) {
		state.payments = payments
	},
	PAYMENT_SEARCH_DATA(state, payments) {
		state.payments.page = 1
		state.payments.total = payments.length
		state.payments.per_page = payments.length
		state.payments.data = payments
	},
	UPDATE_ORDER_STATUS(state, payload) {
		state.assignPendingOrders.data = state.assignPendingOrders.data.filter(
			(data) => data.order_id !== payload.order_id
		)
	},
	ORDERS_COUNT_UPDATE(state, payload) {
		state.count = { ...state.count, ...(payload.count || {}) }
	},
}

let actions = {
    fetchOrdersCount({ commit })
    {
        api.get('/api/orders/count').then((response) =>
        {
            commit('ORDERS_COUNT_UPDATE', response.data)
        })
    },
    onChangeOrderStatus({ commit }, payload = {})
    {
        api.post('/api/orders/change-status', payload).then((res) =>
        {
            commit('ORDERS_UPDATE', payload)
        })
    },
    setOrderState({ commit }, payload = {})
    {
        // return commit('SET', payload)
        api.post(
            '/api/orders/order-assigning-request-driver-response',
            payload
        ).then((res) =>
        {
            // commit('SET', payload)
            commit('UPDATE_ORDER_STATUS', payload)
        })
    },
    onFetchNewOrders({ commit, dispatch, state }, query = {})
    {
        if (state.filter.isLoadingNewOrders)
        {
            commit('SET', { 'filter.isPendingFetchNewOrders': true })
            return
        }

        commit('SET', { 'filter.isLoadingNewOrders': true })
        dispatch('NewOrdersData', {
            page: state.NewOrders.current_page,
            per_page: state.NewOrders.per_page,
            filter: omitEmpties(state.filter.created),
            ...query,
        }).then(() =>
        {
            commit('SET', { 'filter.isLoadingNewOrders': false })
            if (state.filter.isPendingFetchNewOrders)
            {
                commit('SET', { 'filter.isPendingFetchNewOrders': false })
                dispatch('onFetchNewOrders')
            }
        })
    },
    onFetchInProgressOrders({ commit, dispatch, state }, query = {})
    {
        if (state.filter.isLoadingInProgressOrders)
        {
            commit('SET', { 'filter.isPendingFetchInProgressOrders': true })
            return
        }

        commit('SET', { 'filter.isLoadingInProgressOrders': true })
        dispatch('InProgressOrdersData', {
            page: state.inProgressOrders.current_page,
            per_page: state.inProgressOrders.per_page,
            filter: JSON.stringify(omitEmpties(state.filter.progress)),
            ...query,
        }).then(() =>
        {
            commit('SET', { 'filter.isLoadingInProgressOrders': false })
            if (state.filter.isPendingFetchInProgressOrders)
            {
                commit('SET', { 'filter.isPendingFetchInProgressOrders': false })
                dispatch('onFetchInProgressOrders')
            }
        })
    },
    onChangeOrder({ commit, state }, payload)
    {
        const isExist = state.orders.data.some(
            (order) => order.id === payload.order.id
        )
        if (isExist) commit('DELETE', ['orders.data', payload.order.id])
        commit('UNSHIFT', ['orders.data', payload.order])
    },
    onAcceptedOrder({ commit }, payload)
    {
        commit('PUSH', ['orders.data', payload])
    },
    fetchOrderLogs({ commit, state }, payload)
    {
        return new Promise((resolve) =>
        {
            let { page = 1, per_page = 100 } = payload
            api.get(`/api/activity-log/show`, {
                params: { page, per_page, subject_id: payload.id },
            })
                .then((res) =>
                {
                    let hasFetched = state.orderLogs.some(
                        (log) => log.order_id === payload.id
                    )
                    commit(hasFetched ? 'UPDATE' : 'PUSH', [
                        'orderLogs',
                        {
                            ...res.data.activity,
                            order_id: payload.id,
                        },
                    ])
                    resolve([null, res])
                })
                .catch((err) => resolve([err]))
        })
    },
    updateOrder(_, orders)
    {
        return new Promise((resolve, reject) =>
        {
            api.patch('/api/orders/update', orders)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
        })
    },
    createOrders({ commit }, order)
    {
        return new Promise((resolve, reject) =>
        {
            api.post(
                '/api/orders/create',
                toFormData(order, { convertCase: 'snake_case' })
            )
                .then((res) =>
                {
                    resolve(res)
                    commit('CREATE_ORDERS', res.data)
                })
                .catch((err) => reject(err))
        })
    },
    OrdersData({ commit })
    {
        return new Promise((resolve, reject) =>
        {
            api.get('/api/orders/index')
                .then((res) =>
                {
                    const orders = res.data.orders
                    commit('ORDERS_DATA', orders), resolve(res)
                })
                .catch((err) => reject(err))
        })
    },
    updateOrderStore({ commit, state }, payload)
    {
        return new Promise((resolve, reject) =>
        {
            api.post('/api/orders/change-cod-amount', payload)
                .then((res) =>
                {
                    commit('ALL_ORDERS_DATA', {
                        ...state.orders,
                        data: state.orders?.data?.map((order) =>
                            order.id === payload.order_id ? res.data.order : order
                        ),
                    })
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    updateOrderSupervisor({ commit, state }, payload)
    {
        return new Promise((resolve, reject) =>
        {
            api.post('/api/orders/update-order', payload)
                .then((res) =>
                {
                    commit('ALL_ORDERS_DATA', {
                        ...state.orders,
                        data: state.orders?.data?.map((order) =>
                            order.id === payload.order_id ? res.data.order : order
                        ),
                    })
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    getFilteredOrders({ commit }, params = {})
    {
        return new Promise((resolve, reject) =>
        {
            api.get('/api/orders/all-orders', { params })
                .then((res) =>
                {
                    if (!res.data?.orders) return
                    if (params.shipped_orders)
                    {
                        commit('SHIPPED_ORDERS_DATA', res.data.orders)
                    } else if (params.assign_pending)
                    {
                        commit('ASSIGN_PENDING_DATA', res.data.orders)
                    } else if (params.in_progress)
                    {
                        commit('IN_PROGRESS_DATA', res.data.orders)
                    } else
                    {
                        commit('ALL_ORDERS_DATA', res.data.orders)
                    }
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    AllOrdersData({ commit }, page = 1)
    {
        return new Promise((resolve, reject) =>
        {
            api.get('/api/orders/all-orders?page=' + page)
                .then((res) =>
                {
                    const orders = res.data.orders
                    commit('ALL_ORDERS_DATA', orders), resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    OrderById({ commit }, id)
    {
        return new Promise((resolve, reject) =>
        {
            api.get('/api/order/' + id)
                .then((res) =>
                {
                    const order = res.data.order
                    commit('order', order), resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    concatOrders({ commit, state }, payload)
    {
        commit('CONCAT', ['orders.data', uniqueOrders(state, payload)])
    },
    NewOrdersData({ commit }, payload = {})
    {
        return new Promise((resolve, reject) =>
        {
            api.get('/api/orders/created-index', { params: payload })
                .then((res) =>
                {
                    commit('SET', {
                        NewOrders: camelcaseKeys(res.data.orders, { deep: true }),
                    })
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    InProgressOrdersData({ commit }, payload = {})
    {
        return new Promise((resolve, reject) =>
        {
            const { page = 1, per_page = 15, ...query } = payload
            api.get(`/api/orders/in-progress`, {
                params: {
                    page,
                    per_page,
                    ...query,
                },
            })
                .then((res) =>
                {
                    commit('SET', { inProgressOrders: res.data.orders })
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    orderSearchByStatus({ commit }, data)
    {
        return new Promise((resolve, reject) =>
        {
            api.post('/api/orders/order-search-by-status', data)
                .then((res) =>
                {
                    const orders = res.data.orders
                    commit('ALL_ORDERS_SEARCH_DATA', orders), resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    StoreOrdersData({ commit }, payload = {})
    {
        return new Promise((resolve, reject) =>
        {
            api.get('/api/store-order/index', { params: payload })
                .then((res) =>
                {
                    const store_orders = res.data.orders
                    commit('STORE_ORDERS_DATA', store_orders), resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    OrdersUpdate({ commit }, orders)
    {
        return new Promise((resolve, reject) =>
        {
            api.post(`/api/orders/update`, orders)
                .then((res) =>
                {
                    const orders = res.data
                    commit('ORDERS_UPDATE', orders), resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    OrdersDelete({ commit }, id)
    {
        return new Promise((resolve, reject) =>
        {
            api.delete(`/api/orders/delete/${id}`)
                .then((res) =>
                {
                    commit('ORDERS_DELETE', id), resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    PaymentReceived({ commit }, id)
    {
        return new Promise((resolve, reject) =>
        {
            api.get(`/api/orders/payment-received/${id}`)
                .then((res) =>
                {
                    const orders = res.data.order
                    commit('ORDERS_UPDATE', orders), resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    sendOrderNotification(_, res)
    {
        api.post('/api/orders/send-notification', { orders: res.data.orders })
    },
    unassignOrders(_, payload)
    {
        return new Promise((resolve) =>
        {
            api.post(`/api/orders/unassign`, payload)
                .then((res) =>
                {
                    resolve([false, res])
                })
                .catch((err) => resolve([err]))
        })
    },
    Assgin_driver({ dispatch }, order)
    {
        return new Promise((resolve, reject) =>
        {
            api.post(`/api/orders/assign-driver`, order)
                .then((res) =>
                {
                    res.data.orders.forEach((order) =>
                        dispatch('onChangeOrder', { order })
                    )
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                    return err
                })
        })
    },
    Unassign({ commit, dispatch, state }, id)
    {
        return new Promise((resolve, reject) =>
        {
            let order = state.orders.data.find((order) => order.id === id)
            api.get(`/api/orders/unassign/${id}`)
                .then((res) =>
                {
                    const uOrder = {
                        ...order,
                        driver: null,
                        driver_id: null,
                        status: 'created',
                    }
                    if (state.orderView.dialog)
                        commit('SET', {
                            orderView: {
                                dialog: true,
                                data: { ...uOrder },
                            },
                        })
                    delete uOrder.logs
                    dispatch('onChangeOrder', { order: uOrder })
                    resolve(res)
                })
                .catch((err) => reject(err))
        })
    },
    OrdersSearch({ commit }, order)
    {
        return new Promise((resolve, reject) =>
        {
            api.post('/api/orders/order-search', order)
                .then((res) =>
                {
                    const store_orders = res.data.orders

                    commit('STORE_ORDERS_DATA', store_orders), resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    OrdersStatusUpdate({ commit }, order)
    {
        return new Promise((resolve, reject) =>
        {
            api.post('/api/orders/change-status', order)
                .then((res) =>
                {
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    GenerateBolesa(_, order)
    {
        return new Promise((resolve, reject) =>
        {
            api.get(`/api/bolesa/${order.id}/generate`)
                .then((res) =>
                {
                    if (res.response.status === 200) resolve(res.response.data)
                    else reject(res.response.data)
                })
                .catch((error) =>
                {
                    reject(error.data)
                })
        })
    },
    getPayments({ commit }, params = {})
    {
        return new Promise((resolve, reject) =>
        {
            api.get('/api/orders/payments/index', { params })
                .then((res) =>
                {
                    commit('PAYMENT_DATA', res.data.payments)
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    DateWisePaymentList({ commit }, data)
    {
        return new Promise((resolve, reject) =>
        {
            api.post('/api/orders/payments/search', data)
                .then((res) =>
                {
                    commit('PAYMENT_SEARCH_DATA', res.data.payments)
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    filterDataList({ commit }, data)
    {
        return new Promise((resolve, reject) =>
        {
            api.get('/api/orders/get-filter-data', data)
                .then((res) =>
                {
                    resolve(res)
                })
                .catch((err) =>
                {
                    reject(err)
                })
        })
    },
    DuplicateOrder(_, id)
    {
        return new Promise((resolve, reject) =>
        {
            api.get(`/api/orders/${id}/replicate/duplicate`)
                .then((res) =>
                {
                    if (res.status === 200) resolve(res.data)
                    else reject(res.data)
                })
                .catch((err) => reject(err))
        })

    },
    ReplicateReturnOrder(_, id)
    {
        return new Promise((resolve, reject) =>
        {
            api.get(`/api/orders/${id}/replicate/return`)
                .then((res) =>
                {
                    if (res.status === 200) resolve(res.data)
                    else reject(res.data)
                })
                .catch((err) => reject(err))
        })
    },
    ImportSpreadSheetForOrder(_, importData)
    {
        return new Promise((resolve, reject) =>
        {
            api.post('/api/orders/import', importData)
                .then((res) =>
                {
                    if (res.status === 200) resolve(res.data)
                    else reject(res.data)
                })
                .catch((err) => reject(err))
        })
    }
}

const uniqueOrders = (state, ordersRes) =>
{
    const orders = Array.isArray(ordersRes) ? ordersRes : ordersRes.data
    return orders.filter(
        (order) => !state.orders.data.some((o) => o.id === order.id)
    )
}

export default {
    state,
    getters,
    actions,
    mutations,
}
