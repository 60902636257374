import { mapGetters } from 'vuex'

export const useThemes = {
	name: 'UseThemes',
	computed: {
		...mapGetters(['user']),
	},
	watch: {
		'user.companyTheme': {
			deep: true,
			immediate: true,
			handler(v = {}) {
				this.$vuetify.theme.themes.light.primary = v.light.primary
				this.$vuetify.theme.themes.light.secondary = v.light.secondary
			},
		},
	},
}
