import api from '@/api'
import { createPagination } from 'vuelpers'
const pagination = createPagination()

const state = {
	BolesaTransactions: pagination,
	currentPage: 1,
	itemsPerPage: 10,
	totalItems: 0,
}

const getters = {
	BolesaTransactions: (state) => state.BolesaTransactions,
}

const mutations = {
	FETCH_TRANSACTION_DATA(state, transactions) {
		state.BolesaTransactions = transactions
	},
	UPDATE_TRANSACTION_DATA(state, payload) {
		const index = state.BolesaTransactions.data.findIndex(
			(transaction) => transaction.id === payload.id
		)
		if (index !== -1) {
			state.BolesaTransactions.data.splice(index, 1, payload)
		} else {
			state.BolesaTransactions.data.splice(0, 1, payload)
		}
	},
}

const actions = {
	addBolesaDeposit({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/bolesa/wallet/deposit', payload)
				.then((res) => {
					const transaction = res?.data?.transaction
					commit('UPDATE_TRANSACTION_DATA', transaction)
					resolve(transaction)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	getBolesaTransactions({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.get('/api/bolesa/wallet/transactions', { params })
				.then((res) => {
					const transactions = res?.data?.transactions
					commit('FETCH_TRANSACTION_DATA', transactions)
					resolve(transactions)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateTransactionStatus({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/bolesa/wallet/transactions/status-update', {
				...payload,
			})
				.then((res) => {
					const transaction = res?.data?.transaction
					commit('UPDATE_TRANSACTION_DATA', transaction)
					resolve(transaction)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
