import { mapGetters } from 'vuex';
import { useThemes } from '@/mixins/useThemes';
import initializePusherJS from './helpers/initializePusherJS';
export default {
  name: 'App',
  mixins: [useThemes],
  created() {
    if (this.user?.isLoggedIn) {
      this.$store.dispatch('userData').then(() => {
        this.$nextTick(() => {
          if (this.user?.token) {
            initializePusherJS({
              token: this.user.token,
              user_id: this.user.id,
              company_id: this.user.companyId
            });
          }
        });
      });
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
};