import api from '../../api'
import {
	convertKeysToCamelCase,
	createGetters,
	createPaginaion,
	toFormData,
} from 'vuelpers'

const state = () => ({
	sliders: createPaginaion(),
})

const getters = {
	...createGetters({
		sliders: (state, _getter, _rootState, _rootGetters) => ({
			...state.sliders,
			data: state.sliders.data.map((slider) => ({
				...slider,
			})),
		}),
	}),
}
const mutations = {
	FETCH_SLIDERS(state, sliders) {
		state.sliders = sliders
	},
}
const actions = {
	fetchSliders({ commit }, params = {}) {
		return new Promise((resolve) => {
			api.get(`/api/slider`, { params })
				.then((res) => {
					commit('FETCH_SLIDERS', res.data.sliders)
					resolve([false, res])
				})
				.catch((err) => resolve([true, err.response || err]))
		})
	},
	createSlider(_, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/slider', toFormData(payload))
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateSlider(_, payload) {
		return new Promise((resolve, reject) => {
			api.post(`/api/slider/update/${payload.id}`, toFormData(payload))
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	deleteSlider(_, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/slider/${id}`)
				.then((res) => {
					resolve([false, res])
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
