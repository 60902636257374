import Vue from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'

import VueApexCharts from 'vue-apexcharts'
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

import './assets/scss/main.scss'
import './assets/css/tailwind.css'

import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'

import vuetify from './plugins/vuetify'
import Loading from 'vue-loading-overlay'
import DarkReader from './plugins/darkreader'
import MultiFiltersPlugin from './plugins/MultiFilters'

import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from './lib/vuetify-google-autocomplete'

import { Sortable, MultiDrag } from 'sortablejs'
import { setAuthorizationToken } from './mixins/useAuthorization'
import get from 'lodash/get'
import truncate from 'lodash/truncate'
import isEqual from 'lodash/isEqual'
import './prototype'
import { env } from './helpers'

Sortable.mount(new MultiDrag())

Vue.use(Loading)
Vue.use(VueApexCharts)
Vue.use(VueSweetalert2)
Vue.use(Notifications)
Vue.use(VueTelInputVuetify, { vuetify })
Vue.use(MultiFiltersPlugin)
Vue.use(DarkReader)

Vue.use(VueGoogleMaps, {
	load: {
		key: env('GOOGLE_API_KEY'),
		libraries: ['places', 'drawing'],
		useBetaRenderer: false,
	},
})

Vue.use(VuetifyGoogleAutocomplete, {
	vueGoogleMapsCompatibility: true,
})
Vue.prototype._ = {
	get,
	truncate,
	isEqual,
}

Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false

const user = store.getters['user']
if (user) setAuthorizationToken(user.token)

const app = new Vue({
	store,
	router,
	vuetify,
	render: (h) => h(App),
})

app.$mount('#app')
