import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

import { env } from '@/helpers'
import { PAGES, ROLES } from '@/consts'
import { getRouteMeta } from '@/helpers/getRouteMeta'
import { getDefaultPage } from '@/helpers/getDefaultPage'
import { toRouteRoleRegex } from '@/helpers/toRouteRoleRegex'
import { getRoutePathWithRole } from '@/helpers/getRoutePathWithRole'
import { setDocumentTitleFromPath } from '@/helpers/setDocumentTitleFromPath'

const redirectWithRole = (to) => {
	return getRoutePathWithRole(to.path, '/')
}

Vue.use(VueRouter)
const router = new VueRouter({
	mode: 'history',
	base: env('BASE', '/'),
	routes: [
		...[ROLES.OPERATOR, ROLES.SUPERVISOR, ROLES.STORE, ROLES.DRIVER].map(
			(role) => ({
				path: `/${role}`,
				redirect: () => getDefaultPage(),
			})
		),
		{
			path: '',
			redirect: getRoutePathWithRole('/', '/login'),
			component: () => import('@/layouts/MainLayout.vue'),
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: 'admin',
					meta: { role: ROLES.ADMIN },
					redirect: () => getDefaultPage(),
					component: () => import('@/layouts/EmptyLayout.vue'),
					children: [
						{
							path: 'companies/:id',
							name: PAGES.ADMIN_VIEW_COMPANY,
							component: () => {
								return import('@/pages/admin/AdminViewCompanyPage.vue')
							},
						},
						{
							path: '',
							redirect: '/admin/companies',
							component: () => {
								return import('@/layouts/AdminManagementLayout.vue')
							},
							children: [
								{
									path: 'companies',
									name: PAGES.ADMIN_COMPANIES,
									component: () => {
										return import(
											'@/pages/admin/AdminCompaniesPage.vue'
										)
									},
								},
								{
									path: 'orders',
									name: PAGES.ADMIN_ORDERS,
									component: () => {
										return import('@/pages/admin/AdminOrdersPage.vue')
									},
								},
								{
									path: 'stores',
									name: PAGES.ADMIN_STORES,
									component: () => {
										return import('@/pages/admin/AdminStoresPage.vue')
									},
								},
								{
									path: 'drivers',
									name: PAGES.ADMIN_DRIVERS,
									component: () => {
										return import(
											'@/pages/admin/AdminDriversPage.vue'
										)
									},
								},
								{
									path: 'teams',
									name: PAGES.ADMIN_TEAMS,
									component: () => {
										return import('@/pages/admin/AdminTeamsPage.vue')
									},
								},
								{
									path: 'arrears',
									name: PAGES.ADIMN_ARREAR,
									component: () => {
										return import(
											'@/pages/admin/AdminCompanyArrearPage.vue'
										)
									},
								},
								{
									path: 'bolesa-wallets',
									name: PAGES.ADMIN_WALLET,
									component: () => {
										return import(
											'@/pages/admin/AdminCompanyBolesaPage.vue'
										)
									},
								},
							],
						},
					],
				},
				{
					path: toRouteRoleRegex('/', [ROLES.COMPANY, ROLES.SUPERVISOR]),
					meta: { role: [ROLES.COMPANY, ROLES.SUPERVISOR] },
					component: () => import('@/layouts/EmptyLayout.vue'),
					children: [
						{
							path: '',
							redirect: '/company/order-track',
							component: () => {
								return import('@/layouts/CompanyManagementLayout.vue')
							},
							children: [
								{
									path: 'operators',
									name: PAGES.OPERATORS,
									component: () => {
										return import('@/pages/company/OperatorsPage.vue')
									},
								},
								{
									path: 'supervisors',
									name: PAGES.SUPERVISORS,
									component: () => {
										return import(
											'@/pages/company/SupervisorsPage.vue'
										)
									},
								},
							],
						},
						{
							path: 'contracts',
							name: 'ContractsPage',
							component: () => {
								return import('@/pages/ContractsPage.vue')
							},
						},
						{
							path: 'profile',
							name: 'CompanyProfilePage',
							component: () => {
								return import('@/pages/company/CompanyProfilePage.vue')
							},
						},
					],
				},
				{
					path: 'operator/supervisors',
					name: 'OperatorSupervisorsPage',
					props: {
						noPage: false,
					},
					component: () => {
						return import('@/pages/company/SupervisorsPage.vue')
					},
				},
				{
					path: 'store',
					meta: { role: ROLES.STORE },
					redirect: () => getDefaultPage(),
					component: () => import('@/layouts/EmptyLayout.vue'),
					children: [
						{
							name: PAGES.SALLA_CALLBACK,
							component: () => import('@/pages/SallaCallback.vue'),
							path: toRouteRoleRegex('/salla-callback', [ROLES.STORE]),
						},
					],
				},
				{
					name: PAGES.ORDER_TRACK,
					component: () => import('@/pages/OrderTrackPage.vue'),
					path: toRouteRoleRegex('/order-track', [
						ROLES.ADMIN,
						ROLES.OPERATOR,
						ROLES.SUPERVISOR,
						ROLES.COMPANY,
					]),
				},

				{
					name: PAGES.STORES,
					component: () => import('@/pages/StoresPage.vue'),
					path: toRouteRoleRegex('/stores', [
						ROLES.COMPANY,
						ROLES.OPERATOR,
						ROLES.SUPERVISOR,
					]),
				},
				{
					name: PAGES.PICKUP_POINTS,
					component: () => import('@/pages/PickupPointsPage.vue'),
					path: toRouteRoleRegex('/pickup-points', [ROLES.STORE]),
				},
				{
					name: PAGES.ALL_ORDERS,
					component: () => import('@/pages/AllOrdersPage.vue'),
					path: toRouteRoleRegex('/orders', [
						ROLES.COMPANY,
						ROLES.OPERATOR,
						ROLES.SUPERVISOR,
						ROLES.STORE,
						ROLES.DRIVER,
					]),
				},
				{
					name: PAGES.PLATFORM_ORDERS,
					component: () => import('@/pages/order/platformOrdersPage.vue'),
					// component: () => import('@/pages/order/ZidOrdersPage.vue'),
					path: toRouteRoleRegex('/platform', [
						ROLES.COMPANY,
						ROLES.OPERATOR,
						ROLES.SUPERVISOR,
						ROLES.STORE,
					]),
				},
				{
					name: PAGES.ALL_PRODUCTS,
					component: () => import('@/pages/AllProductsPage.vue'),
					path: toRouteRoleRegex('/products', [ROLES.STORE]),
				},
				{
					name: PAGES.ALL_CATEGORIES,
					component: () => import('@/pages/AllCategoriesPage.vue'),
					path: toRouteRoleRegex('/categories', [ROLES.STORE]),
				},
				{
					name: PAGES.BRANDS,
					component: () => import('@/pages/brand/BrandsPage.vue'),
					path: toRouteRoleRegex('/brands', [ROLES.STORE]),
				},
				{
					name: PAGES.SLIDERS,
					component: () => import('@/pages/slider/SlidersPage.vue'),
					path: toRouteRoleRegex('/sliders', [ROLES.STORE]),
				},
				{
					name: PAGES.WEB_PAGES,
					component: () => import('@/pages/webPage/WebPage.vue'),
					path: toRouteRoleRegex('/web-pages', [ROLES.STORE]),
				},
				{
					name: 'OldOrders',
					component: () => {
						return import('../components/Companies/CompanyOrderList.vue')
					},
					path: toRouteRoleRegex('/orders/old', [
						ROLES.COMPANY,
						ROLES.OPERATOR,
						ROLES.SUPERVISOR,
						ROLES.STORE,
						ROLES.DRIVER,
					]),
				},
				{
					name: PAGES.PAYMENTS,
					component: () => import('@/pages/PaymentsPage.vue'),
					path: toRouteRoleRegex('/payments', [
						ROLES.ADMIN,
						ROLES.COMPANY,
						ROLES.OPERATOR,
						ROLES.STORE,
						ROLES.DRIVER,
						ROLES.SUPERVISOR,
					]),
				},
				{
					name: PAGES.DRIVERS,
					component: () => import('@/pages/DriversTeamsPage.vue'),
					path: toRouteRoleRegex('/drivers', [
						ROLES.COMPANY,
						ROLES.OPERATOR,
						ROLES.SUPERVISOR,
					]),
				},
				{
					name: PAGES.TEAMS,
					component: () => import('@/pages/DriversTeamsPage.vue'),
					path: toRouteRoleRegex('/teams', [
						ROLES.COMPANY,
						ROLES.OPERATOR,
						ROLES.SUPERVISOR,
					]),
				},
				{
					name: PAGES.INVOICES,
					component: () => import('@/pages/InvoicesPage.vue'),
					path: toRouteRoleRegex('/invoices', [
						ROLES.ADMIN,
						ROLES.COMPANY,
						ROLES.OPERATOR,
						ROLES.SUPERVISOR,
						ROLES.STORE,
						ROLES.DRIVER,
					]),
				},
				{
					name: PAGES.DELIVERY,
					component: () => import('@/pages/DeliveryPage.vue'),
					path: toRouteRoleRegex('/delivery', [ROLES.ADMIN]),
				},
				{
					name: PAGES.COUNTRIES,
					component: () => import('@/pages/CountriesPage.vue'),
					path: toRouteRoleRegex('/countries', [ROLES.ADMIN]),
				},
				{
					name: PAGES.ANALYTICS,
					component: () => import('@/pages/AnalyticsPage.vue'),
					path: toRouteRoleRegex('/analytics', [
						ROLES.ADMIN,
						ROLES.COMPANY,
						ROLES.STORE,
						ROLES.SUPERVISOR,
					]),
				},
				{
					name: PAGES.ZONE,
					component: () => import('@/pages/ZonePage.vue'),
					path: toRouteRoleRegex('/zone', [ROLES.ADMIN, ROLES.COMPANY]),
				},
				{
					name: PAGES.ARREAR,
					component: () =>
						import('@/pages/company/arrear/ArrearInvoicePage.vue'),
					path: toRouteRoleRegex('/arrear', [ROLES.COMPANY]),
				},
				{
					name: PAGES.WALLET,
					component: () =>
						import('@/pages/company/wallet/BolesaWalletPage.vue'),
					path: toRouteRoleRegex('/bolesa-wallets', [ROLES.COMPANY]),
				},
				{
					path: '/profile',
					name: PAGES.PROFILE,
					component: () => import('@/pages/ProfilePageNew.vue'),
				},
				{
					path: '/settings',
					name: PAGES.SETTINGS,
					component: () => import('@/pages/SettingsPage.vue'),
				},
				{
					path: '/change-password',
					name: PAGES.CHANGE_PASSWORD,
					component: () => import('@/pages/ChangePasswordPage.vue'),
				},
			],
		},
		{
			path: '',
			component: () => import('@/layouts/AuthLayout.vue'),
			meta: {
				requiresUnAuth: true,
			},
			children: [
				{
					path: '/login',
					name: PAGES.LOGIN,
					component: () => import('@/pages/LoginPage.vue'),
				},

				{
					path: '/password/forgot',
					name: PAGES.FORGOT_PASSWORD,
					component: () => import('@/pages/ForgotPasswordPage.vue'),
				},
			],
		},
		{
			path: '/order/tracking/:id',
			name: PAGES.ORDER_TRACKING,
			component: () => import('@/pages/tracking/TrackingPage.vue'),
		},
		// {
		// 	path: '/auth-callback',
		// 	name: PAGES.ZID_CALLBACK,
		// 	component: () => import('@/pages/ZidCallbackPage.vue'),
		// },
		{
			path: '/zid/oauth/redirect',
			name: PAGES.ZID_OAUTH_REDIRECT,
			component: () => import('@/pages/ZidOAuthRedirectPage.vue'),
		},
		{
			path: '/zid/oauth/callback',
			name: PAGES.ZID_OAUTH_CALLBACK,
			component: () => import('@/pages/ZidOAuthCallbackPage.vue'),
		},
		{
			path: '/order-view/:id',
			name: PAGES.ZID_ORDER_VIEW,
			component: () => import('@/pages/ZidOrderViewPage.vue'),
		},

		{
			path: '',
			component: () => import('@/layouts/SignUpLayout.vue'),
			meta: {
				requiresUnAuth: true,
			},
			children: [
				{
					path: '/signup-company',
					name: PAGES.COMPANY_SIGNUP,
					component: () => import('@/pages/CompanySignupPage.vue'),
				},
				{
					path: '/signup-store',
					name: PAGES.STORE_SIGNUP,
					component: () => import('@/pages/StoreSignupPage.vue'),
				},
				{
					path: '/signup-driver',
					name: PAGES.DRIVER_SIGNUP,
					component: () => import('@/pages/DriverSignupPage.vue'),
				},
			],
		},
		{
			path: '/order-track',
			redirect: redirectWithRole,
		},
		{
			path: '/management',
			redirect: redirectWithRole,
		},
		// #ff3
		{
			path: '/contract-agreement',
			name: PAGES.CONTRACT_AGREEMENT,
			component: () => import('@/pages/ContractAgreement.vue'),
		},
		{
			path: '/404',
			name: PAGES.NOT_FOUND,
			component: () => import('@/pages/NotFoundPage.vue'),
		},
		{
			path: '*',
			redirect: (to) => ({
				name: PAGES.NOT_FOUND,
				query: { from: to.path },
			}),
		},
	],
})

router.beforeEach((to, _from, next) => {
	setDocumentTitleFromPath(to.path)
	const user = store.getters.user
	if (user.isLoggedIn && getRouteMeta(to, 'requiresUnAuth')) {
		return next(getDefaultPage())
	}

	if (!user.isLoggedIn && getRouteMeta(to, 'requiresAuth')) {
		return next({ name: PAGES.LOGIN })
	}

	const role = to.params.role || getRouteMeta(to, 'role')

	if (
		role &&
		!user.roleSlugs.some((roleSlug) => {
			if (Array.isArray(role)) {
				return role.some((v) => {
					return v === roleSlug
				})
			}
			return roleSlug === role
		})
	) {
		return next({
			name: PAGES.NOT_FOUND,
			query: {
				from: to.path,
			},
		})
	}

	return next()
})

export default router
