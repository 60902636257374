import { createPaginaion } from 'vuelpers'
import api from '../../api'

const state = () => ({
	companies: createPaginaion(),
	status: '',
	allCompanyDriver: [],
})

let getters = {
	companies: (state) => state.companies,
	allCompanyDriver: (state) => state.allCompanyDriver,
}
let mutations = {
	CREATE_COMPANIES(state, companies) {
		state.companies.data.unshift(companies)
	},
	COMPANIES_DATA(state, companies) {
		state.companies = companies
	},
	COMPANIES_VIEW(state, companiesData) {
		state.status = 'success'
		state.companies = companiesData
	},
	COMPANIES_UPDATE(state, companiesData) {
		state.status = 'success'

		const index = state.companies.data.findIndex(
			(company) => company.id === companiesData.company.id
		)

		if (index !== -1) {
			state.companies.data.splice(index, 1, companiesData.company)
		}
	},
	COMPANIES_DELETE(state, id) {
		state.status = 'success'
		state.companies = state.companies.data.filter(
			(companies) => companies.id !== id
		)
	},
	FETCH_ALL_COMPANY_DRIVERS(state, payload) {
		state.allCompanyDriver = payload
	},
	ADD_DRIVER_TO_COMPANY(state, payload) {
		state.allCompanyDriver.push(payload)
	},
	REMOVE_DRIVER_TO_COMPANY(state, payload) {
		state.allCompanyDriver = state.allCompanyDriver.filter(
			(id) => id !== payload
		)
	},
}

let actions = {
	addDriverToCompany({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/drivers/add-admin-driver', { driver_id: payload })
				.then((res) => {
					if (!res.data.error) {
						commit('ADD_DRIVER_TO_COMPANY', payload)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	removeDriverToCompany({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/drivers/remove-admin-driver', { driver_id: payload })
				.then((res) => {
					if (!res.data.error) {
						commit('REMOVE_DRIVER_TO_COMPANY', payload)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	fetchAllCompanyDrivers({ commit }) {
		return new Promise((resolve, reject) => {
			api.get('/api/drivers/get-admin-driver')
				.then((res) => {
					if (!res.data.error) {
						commit('FETCH_ALL_COMPANY_DRIVERS', res.data.drivers)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	createCompanies({ commit }, companies) {
		return new Promise((resolve, reject) => {
			api.post('/api/companies/create', companies)
				.then((res) => {
					if (res.data.error) {
						const companies = commit('CREATE_COMPANIES', companies)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	CompanyRegistration({ commit }, companies) {
		return new Promise((resolve, reject) => {
			api.post('/api/register-company', companies)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	CompaniesData({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.get('/api/companies/index', { params })
				.then((res) => {
					commit('COMPANIES_DATA', res.data.companies)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	CompaniesUpdate({ commit, rootState }, companies) {
		return new Promise((resolve, reject) => {
			api.post(`/api/companies/update`, companies)
				.then((res) => {
					const companiesData = res.data

					commit(
						'USER_DATA',
						{
							...(rootState.auth.user || {}),
							company: {
								...(rootState.auth?.user?.company || {}),
								...res.data.company,
							},
						},
						{ root: true }
					)
					commit('COMPANIES_UPDATE', companiesData), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	Companiesdelete({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.delete(`/api/companies/delete/${id}`)
				.then((res) => {
					commit('COMPANIES_DELETE', id), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	CompaniesView({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.get(`/api/companies/show/${id}`)
				.then((res) => {
					const companiesData = res.data.company
					commit('COMPANIES_VIEW', companiesData), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
