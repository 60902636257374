import Vue from 'vue'
import store from '../store'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export const toVuetifyThemes = (v = {}) => {
	return {
		light: {
			primary: v?.light?.primary || v?.primary || '#791F9F',
			secondary: v?.light?.secondary || v?.secondary || '#b0bec5',
		},
	}
}

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		themes: toVuetifyThemes(store?.getters['user'].companyTheme),
	},
})
