import { createPagination } from 'vuelpers'
import api from '../../api'
const pagination = createPagination()

const state = () => ({
	companyArrearInvoices: pagination,
	status: 'active',
	arrearError: null,
	arrearErrorMessage: '',
})

const mutations = {
	CREATE_ARREAR_iNVOICE(state, companyArrearInvoices) {
		state.companyArrearInvoices.data.unshift(companyArrearInvoices)
	},
	ARREAR_iNVOICE_DATA(state, companyArrearInvoices) {
		state.companyArrearInvoices = companyArrearInvoices
	},
	ARREAR_INVOICE_DATA(state, companyArrearInvoices) {
		state.status = 'success'
		state.companyArrearInvoices = companyArrearInvoices
	},
	ARREAR_iNVOICE_UPDATE(state, companiesData) {
		state.status = 'success'

		const index = state.companies.data.findIndex(
			(company) => company.id === companiesData.company.id
		)

		if (index !== -1) {
			state.companies.data.splice(index, 1, companiesData.company)
		}
	},
	ARREAR_ERROR_HANDEL(state, data) {
		state.arrearError = true
		state.arrearErrorMessage = data.message
	},
	// 	ARREAR_iNVOICE_DELETE(state, id) {
	// 		state.status = 'success'
	// 		state.companies = state.companies.data.filter(
	// 			(companies) => companies.id !== id
	// 		)
	// 	},
}
const getters = {
	companyArrearInvoices: (state) => state.companyArrearInvoices,
	arrearError: (state) => ({
		isError: state.arrearError,
		message: state.arrearErrorMessage,
	}),
}

const actions = {
	handelArrearError({ commit }, params) {
		return commit('ARREAR_INVOICE_DATA', params)
	},
	createCompaniesArrearInvoices({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/companies/arrear-invoice/add-new', params)
				.then((res) => {
					// commit('ARREAR_INVOICE_DATA', res.data.arrears)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	addArrearRecepit({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/companies/arrear-invoice/add-payment-receipt', params)
				.then((res) => {
					// commit('ARREAR_INVOICE_DATA', res.data.arrears)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateCompaniesArrearInvoices({ commit }, params) {
		console.log(
			'🚀 :: file: arrearInvoice.js:57 :: updateCompaniesArrearInvoices'
		)
		return new Promise((resolve, reject) => {
			api.post('/api/companies/arrear-invoice/update', params)
				.then((res) => {
					// commit('ARREAR_INVOICE_DATA', res.data.arrears)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateArrearInvoicesStatus({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.post('/api/companies/arrear-invoice/update-status', params)
				.then((res) => {
					// commit('ARREAR_INVOICE_DATA', res.data.arrears)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	getCompaniesArrearInvoices({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.get('/api/companies/arrear-invoice', { params })
				.then((res) => {
					commit('ARREAR_INVOICE_DATA', res.data.arrears)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateArrearInvoiceStatus({ commit }, params) {
		return new Promise((resolve, reject) => {
			api.get('/api/companies/arrear-invoice', { params })
				.then((res) => {
					console.log(res)
					commit('ARREAR_iNVOICE_DATA', res.data.arrears)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
